import api from "@/store/services/api";
import { EventBus } from "@/services/eventBus.js";
import Vue from "vue";

export const GETUSERS = ({ commit }, params) => {
  EventBus.$emit("loader", true);
  if (typeof params == "object") {
    return api.get("subscribe", params).then(response => {
      commit("SET_SUBSCRIBERS_LIST", response.data);
      EventBus.$emit("loader", false);
      return response;
    });
  } else {
    return api.get("subscribe").then(response => {
      commit("SET_SUBSCRIBERS_LIST", response.data);
      EventBus.$emit("loader", false);
      return response;
    });
  }
};

export const SENDNOTIFICATION = ({ commit }, params) => {
  EventBus.$emit("loader", true);
  return api.post("user-popup-message", params).then(response => {
    EventBus.$emit("loader", false);
    return response;
  });
};

export const MAKEINTERNALUSER = ({ commit }, params) => {
  EventBus.$emit("loader", true);
  return api.post("internal-user", params).then(response => {
    EventBus.$emit("loader", false);
    return response;
  });
};

export const MAKEFREEUSER = ({ commit }, params) => {
  EventBus.$emit("loader", true);
  return api.post("free-user/add-user", params).then(response => {
    EventBus.$emit("loader", false);
    return response;
  });
};

export const SENDMAIL = ({ commit }, params) => {
  EventBus.$emit("loader", true);
  return api.post("subscribe/send-mail", params).then(response => {
    EventBus.$emit("loader", false);
    return response;
  });
};


export const DELETEUSER = ({ commit }, params) => {
  // console.log('params-----',params);
  EventBus.$emit("loader", true);
  return api.put("subscribe",params).then(response => {
    EventBus.$emit("loader", false);
    return response;
  });
};

export const SUBSCRIBERSEARCH = ({ commit }, params) => {
  EventBus.$emit("loader", true);
  return api.put("users", params).then(response => {
    if (reloadUserList) GETUSERS({ commit, dispatch });
    else GETUSERDETAILS({ commit, dispatch });
    EventBus.$emit("loader", false);
    return response
  });
};
export const GETMAILID = ({ commit }, params) => {
  EventBus.$emit("loader", true);
  return api.put("subscribe/mail-id", params).then(response => {
    EventBus.$emit("loader", false);
    return response;
  });
};
export const DOWNLOADUSERLIST = ({ commit }, params) => {
  // console.log('params-----asdasdasdasd',params)
  EventBus.$emit("loader", true);
  return api.get("dowload-user-detail?type=",params).then(response => {
    // console.log('response------asdasdasdasd',response)
    EventBus.$emit("loader", false);
    return response;
  });
};

export const SUBSCRIPTIONTOGGLE = ({ commit }, params) => {
  // console.log('params-----asdasdasdasd',params)
  EventBus.$emit("loader", true);
  return api.post("enable-subscription",params).then(response => {
    // console.log('response------asdasdasdasd',response)
    EventBus.$emit("loader", false);
    return response;
  });
};

export const SUBSCRIPTIONTOGGLEDETAIL = ({ commit }) => {
  EventBus.$emit("loader", true);
  return api.get("subscription-enable-details").then(response => {
    EventBus.$emit("loader", false);
    return response;
  });
};

export const SUBSCRIPTIONTOGGLEDETAILFOR2 = ({ commit }) => {
  EventBus.$emit("loader", true);
  return api.get("latest-subscription-enable-details").then(response => {
    EventBus.$emit("loader", false);
    return response;
  });
};

export const SUBSCRIPTIONTOGGLEFOR2 = ({ commit }, params) => {
  // console.log('params-----asdasdasdasd',params)
  EventBus.$emit("loader", true);
  return api.post("latest-enable-subscription",params).then(response => {
    // console.log('response------asdasdasdasd',response)
    EventBus.$emit("loader", false);
    return response;
  });
};

export const DISABLEPOPUP = ({ commit },params) => {
  EventBus.$emit("loader", true);
  return api.post("rating-popup",params).then(response => {
    // console.log('response------asdasdasdasd',response)
    EventBus.$emit("loader", false);
    return response;
  });
};


