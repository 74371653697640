import { render, staticRenderFns } from "./Rounds.html?vue&type=template&id=e6a295e2&scoped=true&"
import script from "./Rounds.js?vue&type=script&lang=js&"
export * from "./Rounds.js?vue&type=script&lang=js&"
import style0 from "./Rounds.scss?vue&type=style&index=0&id=e6a295e2&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e6a295e2",
  null
  
)

export default component.exports