import { mapGetters, mapActions } from "vuex";

export default {
  name: 'CommunicationTable',
  props: [
    'items',
    'striped',
    'rowClick',
    'activeTabname',
    'loadMoreShow'
  ],
  data() {
    return {  
      all:false,
      selectedId: [],
      getMailIds:[],
      activeTab:this.activeTabname,
      onceAll:"false",
      active: false,
      scrollTrigerHeight:450,
      page:2,
      loadMoreStatus:false,
      userLength:1,
      fields:[
        { key: "selected", label: "<input type='checkbox' id='selectAll' onclick='selectAll(event)' >", sortable: false },
        { key: "S.No", label: "S.No", sortable: false },
        { key: "subscriber_name", label: "Subscriber Name", sortable: false },
        { key: "subscriber_email", label: "Subscriber Email", sortable: false }
      ]

    }
  },
  watch:{
    activeTabname :function(){
      this.activeTab = this.activeTabname
      this.all = false;
      document.getElementById('selectAll').checked = false;
      var rows = document.getElementsByClassName('communicationRow');
      for(let i=0; i<rows.length; i++){
        var checkbox = rows[i].getElementsByTagName('td')[0]
                      .getElementsByTagName('fieldset')[0]
                      .getElementsByTagName('div')[0]
                      .getElementsByTagName('input')[0];
                      // checkbox.checked
        checkbox.checked = false;
      }
    },
  },
  created(){
    window.selectAll = this.selectAll;
  },
  methods: {
    ...mapActions("Communications", ["GETUSERS","GETMAILID"]),
    rowClicked(item, index, event) {
      this.all=false;
      document.getElementById('selectAll').checked = false;
      var row = document.getElementsByClassName('communicationRow')[index];
      var checkbox = row.getElementsByTagName('td')[0].getElementsByTagName('fieldset')[0].getElementsByTagName('div')[0].getElementsByTagName('input')[0];
      checkbox.checked = !checkbox.checked;
    },
    checkboxClick(event){
      // var individualCheckedIDs = [];
      // var individualCheckedIDs = [];
      // if()
      // individualCheckedIDs.push(event.target.value)
      this.all = false;
      document.getElementById('selectAll').checked = false;
    },
    selectAll(event){
      let checkStatus = event.target.checked;
      if(checkStatus){
        this.onceAll = "true"
        this.all = true
      }else{
        this.onceAll = "false"
        this.all = false
      }
      // this.onceAll = !this.onceAll;
      // this.all = !this.all;
      var rows = document.getElementsByClassName('communicationRow');
      for(let i=0; i<rows.length; i++){
        var checkbox = rows[i].getElementsByTagName('td')[0]
                      .getElementsByTagName('fieldset')[0]
                      .getElementsByTagName('div')[0]
                      .getElementsByTagName('input')[0];
        checkbox.checked = this.all;
      }
    },
    loadMore(){
        let params = {
          type:this.activeTab,
          page:this.page
        }
        this.GETUSERS(params).then((response) => {
          if(response.data.data.total <= 15 || response.data.data.total == response.data.data.to){
            this.loadMoreShow = false
          }else{
            this.loadMoreShow = true          
          }
          this.userLength = this.items.length + response.data.data.data.length
              this.page += 1;
              this.items = [...this.items, ...this.getSubscribersData]
        })
        setTimeout(() => {
          if(this.onceAll == "true"){
            var rows = document.getElementsByClassName('communicationRow');
            for(let i = 0; i<this.userLength; i++){
              var checkbox = rows[i].getElementsByTagName('td')[0]
                                    .getElementsByTagName('fieldset')[0]
                                    .getElementsByTagName('div')[0]
                                    .getElementsByTagName('input')[0];
              checkbox.checked = "true";
            }
          }
        }, 600); 
    },
   async getResult(){
      let checkedIds = [];
      let uncheckedIds = [];
      var getMailIds = [];
      var rows = document.getElementsByClassName('communicationRow');
      for(let i=0; i<rows.length; i++){
        var checkbox = rows[i].getElementsByTagName('td')[0]
                      .getElementsByTagName('fieldset')[0]
                      .getElementsByTagName('div')[0]
                      .getElementsByTagName('input')[0];
                      // checkbox.checked
        checkbox.checked?checkedIds.push(checkbox.value):uncheckedIds.push(checkbox.value);
      }
      if(checkedIds.length > 0 ){
        let params ={
          type:this.activeTab,
          checked :checkedIds,
          not_checked : uncheckedIds,
          check_all : this.onceAll, 
        }
        await this.GETMAILID(params).then((response) => { 
          getMailIds= response.data.data;
          // this.$root.$emit("bv::toggle::modal", "SendMailModal");
        })
      }else{
        event.preventDefault();
        // this.$root.$emit("bv::toggle::modal", "SendMailModal");
      }  
      return {checkedIds,uncheckedIds,getMailIds,selectedAll:this.onceAll};

    }
  },
  computed: {
    ...mapGetters("Communications", ["getSubscribersData"]),
  }
}
