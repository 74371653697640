import { mapGetters } from "vuex";

export default {
  name: 'app-table',

  components: {

  },

  props: [
    'items',
    'striped',
    'rowClick',
    'totalRows',
    'pageCount'
  ],

  data() {
    return {
      visible:true,
      selectedRow: '',
      currentPage: 1,
      selectedComponent: '',
      active: false
    }
  },
  created() {
    if(this.getUserRounds.length > 0){
      this.visible = true
    }else{
      this.visible = false
    }
  },
  mounted(){
  },
  computed: {
    ...mapGetters("Users",["getUserRounds"]),
  },
  methods: {
    
    // rowSelected(item){
    //   if(this.rowClick == 'true'){
    //     this.selectedRow = item
    //     this.$router.push({name:'GamesDetails', query: { item }})
    //   }
    // },

    rowClicked(item) {
      var routename = this.rowClick.name
      var clickable = this.rowClick.item
      switch (routename) {
        case 'games':
          if (clickable == 'true') {
            this.selectedRow = item
            var id = item.game_id
            this.$router.push({ name: 'GamesDetails', query: { id } })
          }
          break
        case 'course':
          if (clickable == 'true') {
            this.selectedRow = item
            var id = item.game_id
            this.$router.push({ name: 'CoursesDetails', query: { id } })
          }
          break
        default:
          break
      }
    }
  }
}
