<template>
  <section class="app-modal">
    <div class="w-100">
      <div class="active-cyan-4 mb-4 searchbar-wrapper">
        <input class="form-control" type="text" v-model="searchText" placeholder="Search" @input="searchDetails" aria-label="Search">
        <span><i class="fa fa-search" aria-hidden="true"></i></span>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "Search",
  data(){
    return {
      searchText: '',
    }
  },
  methods: {
    searchDetails(text){
      let params = this.searchText;
        // this.GETSEARCHDETAILS(params);
    }
  },
}
</script>
<style>
  .searchbar-wrapper{
    width: 25%;
    float: right;
}
.searchbar-wrapper span .fa-search{
  cursor: pointer;
  position: absolute;
  right: 40px;
  top: 24.5%
}
</style>