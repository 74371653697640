import moment from 'moment';
export const SET_USERS_LIST = (state, payload) => {
    let response = payload.data.data
    let paginationData = payload.data.meta.pagination
    let slno = (paginationData.current_page-1)*paginationData.per_page;
    const list = response.map(data => ({
        "S.No":++slno,
        user_Id: data.user_id,
        first_name: data.first_name ? data.first_name.charAt(0).toUpperCase() + data.first_name.slice(1) : '',
        last_name: data.last_name ? data.last_name.charAt(0).toUpperCase() + data.last_name.slice(1) : '',
        nick_name: data.nick_name ? data.nick_name.charAt(0).toUpperCase() + data.nick_name.slice(1) : '',
        "Verified" : data.is_verified == 1 ? 'Yes' : 'No',
        round_count: data.round_count ? data.round_count : '-',
        email_id: data.email ? data.email : '-',
        apple_mail_id: data.apple_mail_id ? data.apple_mail_id : '-',
        apple_id: data.apple_id ? data.apple_id : '-',
        HCP: data.handicap_index ? data.handicap_index : '-',
        created_date: data.created_at ? moment(data.created_at).format('MM-DD-YYYY') : 'Created At',
        actions: '',
        disable_popup : data.is_rating_disabled == 0 ? false : true
    }))

    state.fullList = response
    state.list = list;
};


export const SET_USERROUNDSLIST = (state, payload) => {
    if (payload.data != []) {
        let paginationData = payload.meta.pagination
        let Roundsresponse = payload.data ? payload.data : []
        const Rounds = Roundsresponse.map(data => ({
            // "S.No": ++slno,
            // round_id: data.round_id,
            course_name: data.course_name,
            course_id: data.id_course,
            date: data.date,
            'created_by/players': data.players.join(', '),
            tees: data.teeName,
            time_taken: data.round_total_time,
            status: data.is_end_round == 1 ? 'Completed' : 'Pause'
        }))
        state.userRoundsDetails = Rounds;
        // state.getUserDetails = payload;
        state.roundsPaginationDetails =  paginationData
    }
};


export const SET_USERRTRANSACTIONLIST = (state, payload) => {
    if (payload.data != []) {
        let paginationData = payload.meta.pagination
        let Transactionsresponse = payload.data? payload.data : []
        const Transactions = Transactionsresponse.map(data => ({
            player_to_id: data.player_to,
            player_from_id: data.player_from,
            date: moment(data.date).format('DD-MM-YYYY'),
            type: data.is_manual,
            from: data.from_player_name,
            to: data.to_player_name,
            amount: data.amount
        }))
        state.userTransactionDetails = Transactions;
        // state.getUserDetails = payload;
        state.transactionPaginationDetails =  paginationData
    }
};

export const SET_DETAILS = (state, payload) => {
    if (payload.data != []) {
        let paginationData = payload
        let Roundsresponse = payload.data[0].rounds ? payload.data[0].rounds : []
        let Transactionsresponse = payload.data[0].transactions.records ? payload.data[0].transactions.records : []
        let BankBalanceresponse = payload.data[0].bankbalance ? payload.data[0].bankbalance : {}
        let slno = 0;
        // const Rounds = Roundsresponse.map(data => ({
        //     "S.No": ++slno,
        //     // round_id: data.round_id,
        //     course_name: data.course_name,
        //     course_id: data.id_course,
        //     date: data.date,
        //     'created_by/players': data.players.join(', '),
        //     tees: data.teeName,
        //     time_taken: data.round_total_time ,
        //     status: data.is_end_round == 1 ? 'Completed' : 'Pause'
        // }))
        // const Transactions = Transactionsresponse.map(data => ({
        //     player_to_id: data.player_to,
        //     player_from_id: data.player_from,
        //     date: moment(data.date).format('DD-MM-YYYY'),
        //     type: data.is_manual,
        //     from: data.from_player_name,
        //     to: data.to_player_name,
        //     amount: data.amount
        // }))
        state.userBalanceDetails = BankBalanceresponse
        // state.userTransactionDetails = Transactions;
        // state.userRoundsDetails = Rounds;
        state.getUserDetails = payload;
        // state.paginationDetails = 
    }
};

export const SET_GAME_DETAILS = (state, payload) => {
    let UserGameDetail = payload.data
    const UserGame = UserGameDetail.map(data => ({
        game: data.game_name,
        no_of_times_played: data.played_count,
        players: data.players_count,
        player_list: data.players
    }))
    state.userGameDetails = UserGame
}
