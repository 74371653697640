<template>
  <section class="app-modal">
    <div class="w-100">
      <b-modal
        :id="id"
        centered 
        class="position-relative" 
        @hide="resetState"
        @close="resetState"
        @hidden="resetState"
        @ok="resetState"
      >
        <!-- HEADER -->

        <template slot="modal-header" slot-scope="{ close }">

          <div @click="close()" class="popClose">
            <img   src="@/assets/images/close.png" alt="close" class="img-fluid" />
          </div>
          <h5 class="w-100 text-center text-primary font-weight-bold">{{title}}</h5>
        </template>

        <template slot="default">
            <slot name="modal-card-body"></slot>
        </template>

        <template slot="modal-footer">
            <slot name="modal-card-footer"></slot>
        </template>

      </b-modal>
    </div>
  </section>
</template>

<script>

export default {
  name: "customModal",
  mount(){
  },
  props:{
    id:{
      type:String,
      default:'CustomModal'
    },
    title:{
      type:String,
      default:'Custom Modal'
    }
  },

  methods: {

    //RESET STATE

    resetState(e) {
      this.$emit('resetState')
    }

  }
};
</script>