<template>
  <Modal :id="id" :title="title" @resetState="resetState">
    <template #modal-card-body>
      <div class="text-center">
        <h4 class="font-weight-bold">
            {{heading}}
        </h4>
      </div>
    </template>
    <template #modal-card-footer>
      <div class="text-center w-100">
        <b-button size="sm" pill variant="outline-success" class="px-4 py-2" @click="onSubmit">
            Done
        </b-button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "../Modal";
export default {
  name: "deleteModal",
  props: {

    id: {
      default: "CustomDelete",
      type:String
    },

    title:{
        default: "Delete Modal",
        type:String
    },

    heading:{
        default: "....",
        type:String
    }

  },
  components: {
    Modal
  },
  methods: {
    onSubmit() {
      this.$emit("onSubmit");
    },
    resetState() {
        this.$emit('reset')
    }
  }
};
</script>