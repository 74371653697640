import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store/index'
import './registerServiceWorker'
// import VueGoodTablePlugin from 'vue-good-table';

import './plugins/bootstrap-vue'
import './plugins/highcharts-vue'
import './plugins/SimpleVueValidation'
import './plugins/vue-notifications'
import './plugins/vue-good-table'
import './plugins/vue-json-csv'
import './plugins/vue-toggle'
import { Datetime } from 'vue-datetime';

import AppButton from '@/components/AppButton'
import AppCard from '@/components/AppCard'
import AppPill from '@/components/AppPill'
import AppTable from '@/components/AppTable'

import 'vue-datetime/dist/vue-datetime.css'

Vue.config.productionTip = false

// import app styles
require('./assets/css/styles.css');
require('./assets/fontawesome/css/all.css');
require('quill/dist/quill.core.css')
require('quill/dist/quill.snow.css')
require('quill/dist/quill.bubble.css')

Vue.http.interceptors.push(function (request, next) {
  // modify headers
  request.headers.set('Content-Type', 'application/json');
  request.headers.set('Authorization', localStorage.getItem('Token'));
});
Vue.component('datetime', Datetime)
Vue.component('AppButton', AppButton)
Vue.component('AppCard', AppCard)
Vue.component('AppPill', AppPill)
Vue.component('AppTable', AppTable)
Vue.use(require('vue-moment'));

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
