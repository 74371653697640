
export default {
  name: 'app-card',

  components: {
  },

  props: [],

  data () {
    return {

    }
  },

  computed: {

  },

  mounted () {

  },

  methods: {

  }
}
