import {
  mapGetters,
  mapActions
} from "vuex";
import {
  ModalMixins
} from '@/mixins'
export default {
  name: 'downloads',
  props: [],
  data() {
    return {
      downloadLists: [],
      deleteFileName:''
    }
  },
  mounted() {
    this.getDownloadList()
  },

  methods: {
    ...mapActions("Downloads", ["GETDOWNLOADLIST", "DOWNLOADFILES", "DELETEFILES"]),

    //GET DOWNLOAD LIST

    getDownloadList() {
      // this.downloadLists = [
      //   "Activity-07-28-2022",
      //   "Activity-07-29-2022",
      //   "Rounds-08-01-2022",
      //   "Comm-08-01-2022",
      // ]
      this.GETDOWNLOADLIST('').then((response) => {
        this.downloadLists = response.body.data
      })
    },

    downloadFiles(fileName) {
      this.DOWNLOADFILES(fileName).then((response) => {
        window.open(response.body.message)
      })
    },

    deleteFiles(fileName) {
      console.log('filtename-----',fileName)
      this.$refs['deleteFile-modal'].hide()
      this.DELETEFILES(fileName).then((response) => {
        console.log('res---->>>>',response)
          if (response.status == 200) {
            this.$notify({
              type: "success",
              title: "Successfull",
              text: response.message
            });
            this.getDownloadList()
          } else {
            this.$notify({
              type: "warn",
              title: "Error",
              text: response.message
            });
          }
      })
    },

    closeSubscriptionModal(){
      this.$refs['deleteFile-modal'].hide()
    },

    deleteFileConfirmationModal(file){
      this.deleteFileName = file
      this.$refs['deleteFile-modal'].show()
    }
  }
}
