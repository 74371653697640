export default {
  name: 'my-modal',
  components: {},
  props: ['modalShow'],
  mounted() {
    let Modal = this.$refs['modalShow']

  },
  data() {
    return {}
  },
  computed: {

  },
  methods: {

  }
}
