export const SET_COURSE_LIST = (state, payload) => {
    let response = payload.data
    let pagination = payload.meta.pagination;
    let slno = (pagination.current_page-1)*pagination.per_page;
    const list = response.map(data => ({
        "S.No":++slno,
        // course_id: data.id_course ? data.id_course : '-',
        course_name: data.course_name ? data.course_name : '-',
        rounds: data.rounds ? data.rounds : '-',
        users: data.users ? data.users : '-',
        // users: data.users.join(', '),
        address: data.Address ? data.Address : '-',
        city: data.city ? data.city : '-',
        country: data.country ? data.country : '-',
        // pincode: data.zipCode ? data.zipCode : '-',
        gps: data.gpsAvailable == '1' ? "Available" : "UnAvailable",
        'email':data.email.join(',') ? data.email.join(',') : '-',
        'apple_email_id':data.apple_email_id.join(',') ? data.apple_email_id.join(',') : '-',
        'apple_id':data.apple_id.join(',') ? data.apple_id.join(',') : '-',
        // 'email': data.email[i] ? data.email[i] : '-',
        // 'apple_email_id': data.apple_email_id[i] ? data.apple_email_id[i] : '-',
        // 'apple_id': data.apple_id[i] ? data.apple_id[i] : '-',
    }))
    state.list = list;
};
