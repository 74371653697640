import CommunicationsTable from '@/components/Communication/CommunicationTable';
import SendMailModal from '@/components/SendMailModal';
import UsersGraph from '@/components/Users/UsersGraph';
import { mapGetters, mapActions } from "vuex";
import SimpleVueValidation from 'simple-vue-validator';
import AddAndEditModal from '@/components/Users/AddAndEditModal'
import FilterModal from '@/components/FilterModal'
import DeleteModal from "@/components/DeleteModal";
import ConfirmDisableRatingModal from "@/components/ConfirmDisableRatingModal";

const Validator = SimpleVueValidation.Validator.create({
  templates: {
    Empty: 'This Field is required.'
  }
});;

export default {
  name: 'communications',
  components: {
    AddAndEditModal,
    CommunicationsTable,
    SendMailModal,
    FilterModal,
    UsersGraph,
    DeleteModal,
    ConfirmDisableRatingModal
  },
  props: [],
  data() {
    return {
      show_internal_user : 1,
      utcTime : '',
      allSelected: false,
      checkAllStatus: false,
      showAppTable : true,
      enable_status : false,
      currentDisableRatingStatus: false,
      json_data: [],
      filename: 'Comm_Req.csv',
      column_sort : "asc",
      sort_column_name :'',
      sort_column_type :'',
      columns: [
        {
          label: 'selectall',
          field: 'selectall',
          // filterable :false
        },
        {
          label: 'id',
          field: 'id',
          filterable: false,
        },
        {
          label: 'subscriber_id',
          field: 'subscriber_id',
          filterable: false,
        },
        {
          label: 'email',
          field: 'email',
          filterable: false,
        },
        {
          label: 'updated_at',
          field: 'updated_at',
          filterable: false,
        },
        {
          label: '',
          field: 'delete',
          filterable: false,
        },
      ],
      app_columns: [
        {
          label: 'selectall',
          field: 'selectall',
          // filterable :false
        },
        {
          label: 'id',
          field: 'id',
          filterable: false,
        },
        {
          label: 'first_name',
          field: 'first_name',
          filterable: true,
          // formatValue: this.fnameSorting('first_name')
        },
        {
          label: 'last_name',
          field: 'last_name',
          filterable: true,
        },
        {
          label: 'email',
          field: 'email',
          filterable: true,
        },
        {
          label: 'apple_email_id',
          field: 'apple_email_id',
          filterable: true,
        },
        {
          label: 'apple_id',
          field: 'apple_id',
          filterable: true,
        },
        {
          label: 'user_id',
          field: 'user_id',
          filterable: true,
          type: "date",
          inputFormat: 'DD-MM-YYYY HH:mm:ss',
          outputFormat: 'DD-MM-YYYY HH:mm:ss'
        },
        {
          label: 'subscription_type',
          field: 'subscription_type',
          filterable: true,
        },
        {
          label: 'trail_start_date',
          field: 'trail_start_date',
          filterable: true,
          type: "number"
        },
        {
          label: 'trail_end_date',
          field: 'trail_end_date',
          filterable: true,
          type: "number"
        },
        {
          label: 'subscription_start_date',
          field: 'subscription_start_date',
          filterable: true,
        },
        {
          label: 'subscription_end_date',
          field: 'subscription_end_date',
          filterable: true,
        },
        {
          label: 'cancel_date',
          field: 'cancel_date',
          filterable: true,
        },
        
        {
          label: 'last_played',
          field: 'last_played',
          filterable: true,
        },
        {
          label: 'round_played',
          field: 'round_played',
          filterable: true,
          type: "number"
        },
        {
          label: 'rounds_with_game',
          field: 'rounds_with_game',
          filterable: false,
        },
        {
          label: 'player_count',
          field: 'player_count',
          filterable: true,
          type: "number"
        },
        {
          label: 'total_bet',
          field: 'total_bet',
          filterable: false,
        },
        {
          label: 'app_version',
          field: 'app_version',
          filterable: false,
        },
        {
          label: 'country',
          field: 'country',
          filterable: false,
        },
        {
          label: 'cancellation_date',
          field: 'cancellation_date',
          filterable: false,
        },
        {
          label: 'pending_count',
          field: 'pending_count',
          filterable: false,
        },
        {
          label: 'packages',
          field: 'packages',
          filterable: false,
        },{
          label: 'upgrade_type',
          field: 'upgrade_type',
          filterable: false,
        },{
          label: 'upgrade_date',
          field: 'upgrade_date',
          filterable: false,
        },{
          label: 'is_deleted',
          field: 'is_deleted',
          filterable: false,
        },{
          label: 'delete_date',
          field: 'delete_date',
          filterable: false,
        },{
          label: 'is_rating_disabled',
          field: 'is_rating_disabled',
          filterable: false,
        },
        // {
        //   label: 'family_shared',
        //   field: 'family_shared',
        //   filterable: true,
        // },
        // {
        //   label: 'is_verified',
        //   field: 'is_verified',
        //   filterable: false,
        // },
        // {
        //   label: 'internal_user',
        //   field: 'internal_user',
        //   filterable: false,
        // },
        // {
        //   label: 'subscribtion_date',
        //   field: 'subscribtion_date',
        //   filterable: true,
        //   type: "number"
        // },
        // {
        //   label: 'cancellation_date',
        //   field: 'cancellation_date',
        //   filterable: true,
        //   type: "number"
        // },
      
        // {
        //   label: 'year_start_date',
        //   field: 'year_start_date',
        //   filterable: true,
        //   type: "number"
        // },
        // {
        //   label: 'year_end_date',
        //   field: 'year_end_date',
        //   filterable: true,
        //   type: "number"
        // },
        // {
        //   label: '',
        //   field: 'delete',
        //   filterable: false,
        // },
      ],
      rows1: [],
      tableResultRows: [],
      counter1: 0,
      counter2: 0,
      counter3: 0,
      counter4: 0,
      counter5: 0,
      checkeddData: {
        checkedIds: [],
        uncheckedIds: []
      },
      fetchSelectedData : 'All',
      rows: null,
      perPage: null,
      currentPage: 1,
      users: [],
      timeVariable: null,
      sendMailModalId: "SendMailModal",
      disableRatingModalId: "DisableRatingModal",
      activeTab: 'app',
      startDate: "",
      endDate: "",
      selectedRow: null,
      getResult: {},
      subscriberSearch: '',
      view: 2,
      totalCount: '',
      loadMoreStatus: false,
      getMailIds: '',
      notificationContent : 'We could not reach you via email, please update your email in Menu > My Account and resubmit any support tickets.',
      deleteModalId: "UserDetailDeleteModal",
      disable_sub_data: 0,
      disableSubscriptionSelected:0,
      disable_sub_data_for_2: 0,
      disable_popup:0,
      disableRatingTitle:'Enable Rating'
    }
  },
  validators: {
    startDate: function (value) {
      return Validator.value(value).required()
    },
    endDate: function (value) {
      return Validator.value(value).required()
    }
  },
  computed: {
    ...mapGetters("Communications", ["getSubscribersData"]),
  },
  created(){
      let UTC = new Date().toUTCString();
      this.utcTime = UTC
  },
  mounted(){
    this.getSubDetails();
  },
  // watch: {
  //   subscriberSearch: function () {
  //     clearTimeout(this.timeVariable);
  //     let that = this;
  //     this.timeVariable = setTimeout(function () {
  //       that.searchData();
  //     }, 1000);
  //   }
  // },
  methods: {
    ...mapActions("Communications", ["GETUSERS", "GETMAILID","DELETEUSER","DOWNLOADUSERLIST","SENDNOTIFICATION","MAKEINTERNALUSER","MAKEFREEUSER", "SUBSCRIPTIONTOGGLE", "SUBSCRIPTIONTOGGLEDETAIL","SUBSCRIPTIONTOGGLEDETAILFOR2","SUBSCRIPTIONTOGGLEFOR2", "DISABLEPOPUP"]),
    date: function (date) {
      return moment(date).format('MMMM Do YYYY');
    },

    fetchData(value){
        // console.log('value---->>>>>DDDd',value)
        this.fetchSelectedData = value
        let params = {
          filter : value,
          internal_user : this.show_internal_user,
          type: this.activeTab,
          search: this.subscriberSearch
        }
      this.getUserList(params);
    },

    makeInternalUser(){
      var params = {
        internal_user : this.show_internal_user,
        checked: this.checkeddData.checkedIds,
        not_checked: this.checkeddData.uncheckedIds,
        check_all: this.checkAllStatus,
      };
      // console.log('checkallstatus=====',this.checkAllStatus)
      // console.log('checkedIds=====',this.checkeddData.checkedIds)
      if (this.checkeddData.checkedIds != "") {
        // console.log('checkallstatus=====1111',this.checkAllStatus)
      // console.log('checkedIds=====1111',this.checkeddData.checkedIds)
        this.MAKEINTERNALUSER(params).then(response => {
          if (response.status == 200) {
            this.$notify({
              type: "success",
              title: "Successfull",
              text: response.message
            });
            // setTimeout(() => {
            //   location.reload();
            // }, 600);
          } else {
            this.$notify({
              type: "warn",
              title: "Error",
              text: response.message
            });
          }
        });
      } else {
        // console.log('checkallstatus=====2222',this.checkAllStatus)
        // console.log('checkedIds=====222',this.checkeddData.checkedIds)
          this.$notify({
              type: "warn",
              title: "Error",
              text: "Please Select the User First"
            });
      }
    },

    makeFreeUser(){
      var params = {
        internal_user : this.show_internal_user,
        checked: this.checkeddData.checkedIds,
        not_checked: this.checkeddData.uncheckedIds,
        check_all: this.checkAllStatus,
      };
      // console.log('checkallstatus=====',this.checkAllStatus)
      // console.log('checkedIds=====',this.checkeddData.checkedIds)
      if (this.checkeddData.checkedIds != "") {
        // console.log('checkallstatus=====1111',this.checkAllStatus)
      // console.log('checkedIds=====1111',this.checkeddData.checkedIds)
        this.MAKEFREEUSER(params).then(response => {
          if (response.status == 200) {
            this.$notify({
              type: "success",
              title: "Successfull",
              text: response.message
            });
            // setTimeout(() => {
            //   location.reload();
            // }, 600);
          } else {
            this.$notify({
              type: "warn",
              title: "Error",
              text: response.message
            });
          }
        });
      } else {
        // console.log('checkallstatus=====2222',this.checkAllStatus)
        // console.log('checkedIds=====222',this.checkeddData.checkedIds)
          this.$notify({
              type: "warn",
              title: "Error",
              text: "Please Select the User First"
            });
      }
    },

    disableSubscription(value){
      if(value == true){
        let params = {
          enable_subscription : 1
        }
        this.SUBSCRIPTIONTOGGLEFOR2(params).then(response => {
          console.log('res---->>>>',response)
          if (response.status == 200) {
            this.disable_sub_data = 1
            this.$notify({
              type: "success",
              title: "Successfull",
              text: response.message
            });
          } else {
            this.$notify({
              type: "warn",
              title: "Error",
              text: response.message
            });
          }
          this.$refs['subscription-modal'].hide()
        });
      }else if(value == false){
          let params = {
            enable_subscription : 0
          }
          this.SUBSCRIPTIONTOGGLEFOR2(params).then(response => {
            console.log('res---->>>>',response)
            if (response.status == 200) {
            this.disable_sub_data = 0
              this.$notify({
                type: "success",
                title: "Successfull",
                text: response.message
              });
            } else {
              this.$notify({
                type: "warn",
                title: "Error",
                text: response.message
              });
            }
            this.$refs['subscription-modal'].hide()
          });
        }
    },

    // disableSubscriptionFor2(data){
    //   console.log('data----->>>>>>>',data.value);
    //   if(data.value == true){
    //     let params = {
    //       enable_subscription : 1
    //     }
    //     this.SUBSCRIPTIONTOGGLEFOR2(params).then(response => {
    //       console.log('res---->>>>',response)
    //       if (response.status == 200) {
    //         this.disable_sub_data_for_2 = 1
    //         this.$notify({
    //           type: "success",
    //           title: "Successfull",
    //           text: response.message
    //         });
    //       } else {
    //         this.$notify({
    //           type: "warn",
    //           title: "Error",
    //           text: response.message
    //         });
    //       }
    //     });
    //   }else if(data.value == false){
    //       let params = {
    //         enable_subscription : 0
    //       }
    //       this.SUBSCRIPTIONTOGGLEFOR2(params).then(response => {
    //         console.log('res---->>>>',response)
    //         if (response.status == 200) {
    //         this.disable_sub_data_for_2 = 0
    //           this.$notify({
    //             type: "success",
    //             title: "Successfull",
    //             text: response.message
    //           });
    //         } else {
    //           this.$notify({
    //             type: "warn",
    //             title: "Error",
    //             text: response.message
    //           });
    //         }
    //       });
    //     }
    // },

    getSubDetails(){
      this.SUBSCRIPTIONTOGGLEDETAILFOR2().then(response => {
        console.log('res---->>>>',response)
        if (response.status == 200) {
          this.disable_sub_data = response.data.data
          this.disable_popup = response.data.rating == true ? 0 : 1;

          console.log('disable_sub_data----',this.disable_sub_data)
        } else {
        }
      });
    },

    showInternalUser(data){
      if(data.value == true){
        this.show_internal_user = 0;
        let params = {
          search: this.subscriberSearch,
          internal_user : this.show_internal_user,
          type: 'app',
          filter: this.fetchSelectedData
        }
        this.getUserList(params);
      }else{
        this.show_internal_user = 1;
        let params = {
          search: this.subscriberSearch,
          internal_user : this.show_internal_user,
          type: 'app',
          filter: this.fetchSelectedData
        }
        this.getUserList(params);
      }
    },

    //Toggle Modal

    deletetoggleModal(modalId) {
      this.$root.$emit("bv::toggle::modal", modalId);
    },

    notificationModal() {
      // this.deleteId = userid
      if(this.checkeddData.checkedIds != "") {
        this.$refs['notification-modal'].show()
      }else{
        this.$notify({
          type: "warn",
          title: "Error",
          text: "Please Select the User First"
        });
      }
    },

    submitNotification(){
        var params = {
          internal_user : this.show_internal_user,
          message:this.notificationContent,
          checked: this.checkeddData.checkedIds,
          not_checked: this.checkeddData.uncheckedIds,
          check_all: this.checkAllStatus,
        };
        if (this.notificationContent != "" && this.checkeddData.checkedIds != "") {
          this.SENDNOTIFICATION(params).then(response => {
            if (response.status == 200) {
              this.$notify({
                type: "success",
                title: "Successfull",
                text: response.message
              });
              setTimeout(() => {
                location.reload();
              }, 600);
            } else {
              this.$notify({
                type: "warn",
                title: "Error",
                text: response.message
              });
            }
          });
          this.commentErr = false;
          this.subjectErr = false;
        } else {
          if (this.mailSubject == "") {
            this.subjectErr = true;
          }else if (this.mailContent == "") {
            this.commentErr = true;
          } else {
            this.subjectErr = false;
            this.commentErr = false;
            this.$notify({
                type: "warn",
                title: "Error",
                text: "Mail Ids not found"
              });
          }
        }
    },

    async toggleModal(modalId) {
      console.log('')
      if (this.checkeddData.checkedIds.length > 0) {
        // console.log()
        let params1 = {
          internal_user : this.show_internal_user,
          type: this.activeTab,
          checked: this.checkeddData.checkedIds,
          not_checked: this.checkeddData.uncheckedIds,
          check_all: this.checkAllStatus,
        }
        this.GETMAILID(params1).then((response) => {
          this.getMailIds = response.data.data;
          var params = {
            internal_user : this.show_internal_user,
            check_all: this.checkAllStatus,
            data: this.checkeddData,
            mailIds: this.getMailIds
          }
          this.getResult = params
        })
      } else {
        this.getResult = {}
        event.preventDefault();
      }
      this.$root.$emit('bv::toggle::modal', modalId)
    },

    subscriptionModal(data){
      this.disableSubscriptionSelected = data.value
      this.$refs['subscription-modal'].show()
    },

    closeSubscriptionModal(){
      this.$refs['subscription-modal'].hide()
    },

    deleteUser(){
      if (this.checkeddData.checkedIds.length > 0) {
        // console.log()
        let params1 = {
          internal_user : this.show_internal_user,
          select: this.checkeddData.checkedIds,
          not_select: this.checkeddData.uncheckedIds,
          select_all: this.checkAllStatus,
        }
        this.toggleModal(this.deleteModalId);
        // console.log('oaramssssss-----',params1);
        this.DELETEUSER(params1).then((response) => {
          if (response.status == 200) {
            this.$notify({
              type: "success",
              title: "Successfull",
              text: response.message
            });
            location.reload();
          }else {
            this.$notify({
              type: "warn",
              title: "Error",
              text: response.message
            });
          }
        });
      } else {
        event.preventDefault();
      }
    },

    downloadUserList(data){
      if(data == 'all'){
        this.filename = "Comm_all.csv"
      }else if(data == "is_registered"){
        this.filename = "Comm_Reg.csv"
      }else if(data == "is_trial"){
        this.filename = "Comm_Trial.csv"
      }
      let params = {
        download_type : data,
        internal_user : this.show_internal_user,
        type: this.activeTab,
      }
      this.DOWNLOADUSERLIST(params).then((response) => {
      // console.log('response------11111111111111111',response)
        if (response.status === 200) {
          // window.open(
          //   response.url,
          //   "_blank"
          // );
          this.json_data = response.data.data
          // console.log('response.data-------',this.json_data)
          setTimeout(() => {
            this.$refs.fileInput.$el.click();
          }, 800);
        } else {
        }
      })
    },
    // Checkbox click

    checkboxClick(event) {
      if (event.target.checked == false) {
        this.checkeddData.uncheckedIds.push(event.target.value)
      }
      // console.log('this.checkeddata.uncheckedIds',this.checkeddData.uncheckedIds);
      // console.log('this.checkeddata.uncheckedIds',this.checkeddData.checkedIds);
    },

    // SEARCH

    searchData() {
      if (!this.checkAllStatus) {
        // document.getElementById("checkBoxInput").checked = true;  
      }
      let params = {
        internal_user : this.show_internal_user,
        type: this.activeTab,
        search: this.subscriberSearch
      }
      this.GETUSERS(params).then((response) => {
        if (response.status === 200) {
          let responseData = response.data.data
          this.totalCount = responseData.total;
          this.currentPage = responseData.current_page;
          this.perPage = responseData.per_page;
          if (this.allSelected) {
            this.tableResultRows = response.data.data.data.map((object, index) => {
              object.selected = false;
              return object;
            })
            this.rows1 = response.data.data.data.map((object, index) => {
              object.selected = false;
              return object;
            })
            let that = this;
            this.rows1.forEach(item => {
              // this.checkeddData.checkedIds.splice(this.checkeddData.checkedIds.indexOf(item), 1);
              this.checkeddData.checkedIds.push(item.id)
              this.checkeddData.checkedIds = [...new Set(this.checkeddData.checkedIds)]
                this.checkeddData.uncheckedIds.forEach(id => {
                  if (this.checkeddData.checkedIds.includes(Number(id))) {
                    // console.log('----hrere')
                    this.checkeddData.checkedIds.splice(this.checkeddData.checkedIds.indexOf(Number(id)), 1);
                  }                    
                });
            });
          }
          else {
            this.tableResultRows = response.data.data.data.map((object, index) => {
              object.selected = false;
              return object;
            })
            this.rows1 = response.data.data.data.map((object, index) => {
              object.selected = false;
              return object;
            })
          }
        } else {
        }
        if (response.data.data.total <= 15 || response.data.data.total == response.data.data.to) {
          this.loadMoreStatus = false
        } else {
          this.loadMoreStatus = true
        }
      })
    },

    fnameSorting(column_name){
      // console.log('dataaaaaaaaaa------------->>>>>>>>>',column_name);
      if(this.column_sort == 'asc'){
        this.sort_column_type = 'asc'
        if(column_name == "total_bet") {
          this.sort_column_name = 'bet_count';
          let params = {
            internal_user : this.show_internal_user,
            type: this.activeTab,
            column_name : 'bet_count',
            order_by : this.column_sort,
            search: this.subscriberSearch,
            filter: this.fetchSelectedData
          }
          this.getUserList(params);
          this.column_sort = 'desc'
        }else if(column_name == "trail_end_date") {
          this.sort_column_name = 'trail_start_date';
          let params = {
            internal_user : this.show_internal_user,
            type: this.activeTab,
            column_name : 'trail_start_date',
            order_by : this.column_sort,
            search: this.subscriberSearch,
            filter: this.fetchSelectedData
          }
          this.getUserList(params);
          this.column_sort = 'desc'
        }else if(column_name == "subscribtion_date") {
          this.sort_column_name = 'subcribe_date';
          let params = {
            internal_user : this.show_internal_user,
            type: this.activeTab,
            column_name : 'subcribe_date',
            order_by : this.column_sort,
            search: this.subscriberSearch,
            filter: this.fetchSelectedData
          }
          this.getUserList(params);
          this.column_sort = 'desc'
        }else if(column_name == "subscription_type") {
          this.sort_column_name = 'subscription_type';
          let params = {
            internal_user : this.show_internal_user,
            type: this.activeTab,
            column_name : 'subscription_type',
            order_by : this.column_sort,
            search: this.subscriberSearch,
            filter: this.fetchSelectedData
          }
          this.getUserList(params);
          this.column_sort = 'desc'
        }else{
          this.sort_column_name = column_name;
          let params = {
            internal_user : this.show_internal_user,
            type: this.activeTab,
            column_name : column_name,
            order_by : this.column_sort,
            search: this.subscriberSearch,
            filter: this.fetchSelectedData
          }
            this.getUserList(params);
            this.column_sort = 'desc'
        }       
      }
      else{
        this.sort_column_type = 'desc'
        if(column_name == "total_bet") {
          this.sort_column_name = 'bet_count';
          let params = {
            internal_user : this.show_internal_user,
            type: this.activeTab,
            column_name : 'bet_count',
            order_by : this.column_sort,
            search: this.subscriberSearch,
            filter: this.fetchSelectedData
          }
          this.getUserList(params);
          this.column_sort = 'asc'
        }else if(column_name == "trail_end_date") {
          this.sort_column_name = 'trail_start_date';
          let params = {
            internal_user : this.show_internal_user,
            type: this.activeTab,
            column_name : 'trail_start_date',
            order_by : this.column_sort,
            search: this.subscriberSearch,
            filter: this.fetchSelectedData
          }
          this.getUserList(params);
          this.column_sort = 'asc'
        }else if(column_name == "subscription_type") {
          this.sort_column_name = 'subscription_type';
          let params = {
            internal_user : this.show_internal_user,
            type: this.activeTab,
            column_name : 'subscription_type',
            order_by : this.column_sort,
            search: this.subscriberSearch,
            filter: this.fetchSelectedData
          }
          this.getUserList(params);
          this.column_sort = 'asc'
        }else if(column_name == "subscribtion_date") {
          this.sort_column_name = 'subcribe_date';
          let params = {
            internal_user : this.show_internal_user,
            type: this.activeTab,
            column_name : 'subcribe_date',
            order_by : this.column_sort,
            search: this.subscriberSearch,
            filter: this.fetchSelectedData
          }
          this.getUserList(params);
          this.column_sort = 'asc'
        }else{
          this.sort_column_name = column_name;
          let params = {
            internal_user : this.show_internal_user,
            type: this.activeTab,
            column_name : column_name,
            order_by : this.column_sort,
            search: this.subscriberSearch,
            filter: this.fetchSelectedData
          }
            this.getUserList(params);
            this.column_sort = 'asc'
        }  
      }
    },

    // User List
     
    getUserList(params) {
      // console.log('params-------->>>>>>>>>',params)
      this.GETUSERS(params).then((response) => {
        if (response.status === 200) {
          console.log('response?.....',response.body.select_all)
          let responseData = response.data.data;
          this.totalCount = responseData.total;
          this.currentPage = responseData.current_page;
          this.perPage = responseData.per_page;
          if (this.allSelected) {
            this.tableResultRows = response.data.data.data.map((object, index) => {
              object.selected = false;
              return object;
            })
            this.rows1 = response.data.data.data.map((object, index) => {
              object.selected = false;
              return object;
            })
            let that = this;
            this.rows1.forEach(item => {
              // this.checkeddData.checkedIds.splice(this.checkeddData.checkedIds.indexOf(item), 1);
              this.checkeddData.checkedIds.push(item.id)
              this.checkeddData.checkedIds = [...new Set(this.checkeddData.checkedIds)]
                this.checkeddData.uncheckedIds.forEach(id => {
                  if (this.checkeddData.checkedIds.includes(Number(id))) {
                    // console.log('----hrere')
                    this.checkeddData.checkedIds.splice(this.checkeddData.checkedIds.indexOf(Number(id)), 1);
                  }                    
                });
            });
          }
          else {
            this.tableResultRows = response.data.data.data.map((object, index) => {
              object.selected = false;
              return object;
            })
            this.rows1 = response.data.data.data.map((object, index) => {
              object.selected = false;
              return object;
            })
          }
        }
      })
    },

    // View User Detials
    viewUserDetails(userId){

      let routeUrl = {
        path: '/users/details?id='+userId, 
      }
      console.log('routeUrl----',routeUrl)
      this.$router.push(routeUrl);
    },

    // Toggle SelectAll

    toggleSelectAll(event) {
      this.checkAllStatus = event.target.checked
      this.allSelected = !this.allSelected;

      this.tableResultRows.forEach(row => {
        if (this.allSelected) {
          // this.allSelected = true
          row.selected = true;
          this.checkeddData.checkedIds.push(row.id)
        } else {
          // this.allSelected = true            
          row.selected = false;
          this.checkeddData.checkedIds = []
        }
      })
    
      
    },

    //HANDLE PAGINATION

    handlePagination(val) {
      let params = {
        page: val,
        internal_user : this.show_internal_user,
        ...(
          this.activeTab
            ? { type: this.activeTab }
            : {
              start_date: this.startDate,
              end_date: this.endDate
            }),
        column_name : this.sort_column_name,
        order_by : this.sort_column_type,
        search: this.subscriberSearch,
        filter: this.fetchSelectedData
      }
      window.scrollTo({ top: 0, behavior: 'smooth' })
      this.getUserList(params)
    },

    //HANDLE FILTER TABS

    handleFilter(value = 'app', event) {
      this.allSelected = false
      document.getElementById("checkBoxInput").checked = false;
      this.checkeddData.checkedIds = [],
        this.checkeddData.uncheckedIds = []
      this.activeTab = value
      this.startDate = ''
      this.checkAllStatus = false
      this.endDate = ''
      this.subscriberSearch = ''
      let params = {
        internal_user : this.show_internal_user,
        type: value,
        page: 1,
        filter: this.fetchSelectedData
      }
        this.rows1 = []
        this.totalCount = 0;
    },

    confirmDisableRatintPopUP(value, modalId){
      this.currentDisableRatingStatus = value
      this.disableRatingTitle = value ? 'Enable Rating' : 'Disable Rating'
      this.$root.$emit("bv::toggle::modal", modalId)
    },

    disablePopup(value,id){
      let params
        if(value == true){
          params ={
            "select_all": id ? false : true,
            "user_id": id,
            "status": 0 
          }
        }else if(value == false){
          params ={
            "select_all": id ? false : true,
            "user_id": id,
            "status": 1
          }
        }
        console.log('params-----',params)
        this.DISABLEPOPUP(params).then((response) => {
          console.log('res---->>>>',response)
          if (response.status == 200) {
            if(!id){
              this.$root.$emit("bv::toggle::modal", this.disableRatingModalId)
              this.disable_popup = value == true ? 1 : 0
            }
            this.$notify({
              type: "success",
              title: "Successfull",
              text: response.message
            });

            if(id){
              let index = this.rows1.findIndex(x => x.id === id);
              if (index !== -1) {
                this.rows1[index].is_rating_disabled = value == true ? 'Enabled' : 'Disabled';
              }
            }else{
              this.rows1.map( (x,y) => {
                console.log('x----',y)
                this.rows1[y].is_rating_disabled = value == true ? 'Enabled' : 'Disabled'
              })
            }

            console.log('rows1-----',this.rows1)
            console.log('index-----',this.index)

            // this.fetchData(this.fetchSelectedData);
          } else {
            this.$notify({
              type: "warn",
              title: "Error",
              text: response.message
            });
          }
        });
     
    }
  }
}
























