import {Chart} from 'highcharts-vue'
var Highcharts = require('highcharts');


export default {
  name: 'user-statistics',

  components: {
    highcharts: Chart 
  },

  props: ['totalDownloadGraph'],

  data () {
    return {
    xAxisData :[],
    yAxisData: [],
    downloadGraph:[],
    showChart : false,
      chartOptions: {
        chart: {
            type: 'areaspline'
        },
        title: {
            text: ''
        },
        // legend: {
        //     layout: 'vertical',
        //     align: 'left',
        //     verticalAlign: 'top',
        //     x: 150,
        //     y: 100,
        //     floating: true,
        //     borderWidth: 1,
        //     backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
        // },
        xAxis: {
            categories: [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ]
            // plotBands: [{ // visualize the weekend
            //     from: 4.5,
            //     to: 6.5,
            //     color: 'rgba(68, 170, 213, .2)'
            // }]
        },
        yAxis: {
            title: {
                text: 'No. of Downloads'
            }
        },
        tooltip: {
            shared: true,
            valueSuffix: ' Downloads'
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            areaspline: {
                fillOpacity: 0.5
            }
        },
        series: [{
            name: 'Downloads',
            data: this.totalDownloadGraph.Count,
            color:'#bad653'
        }]
      }

    }
  },

  mounted () {
          // console.log('this.chartssss---->>>>',this.totalDownloadGraph)
  },

  methods: {
  }
}
