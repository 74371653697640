export default {
  name: 'app-pill',
  components: {},
  props: {
    activeState: {},
    handleFilter: { type: Function }
  },
  data() {
    return {

    }
  },
  computed: {

  },
  mounted() {
  },
  methods: {
    handlePill() {
      this.$emit('handleFilter')
    }
  }
}
