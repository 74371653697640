import { render, staticRenderFns } from "./AppPill.html?vue&type=template&id=de2f95fe&scoped=true&"
import script from "./AppPill.js?vue&type=script&lang=js&"
export * from "./AppPill.js?vue&type=script&lang=js&"
import style0 from "./AppPill.scss?vue&type=style&index=0&id=de2f95fe&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de2f95fe",
  null
  
)

export default component.exports