import moment from 'moment';
export const SET_SUBSCRIBERS_LIST = (state, payload) => {

    let response = payload.data.data
    let pagination = payload.data;
    let slno = (pagination.current_page-1)*pagination.per_page;
    // console.log('ddaaaaaatttaaaa---->>>>',response)
    const list = response.map(data => ({
        "S.No":++slno,
        id: data.name ? data.name.charAt(0).toUpperCase() + data.name.slice(1) : '-', 
        subscriber_email: data.email,
        subscriber_id: data.id
    }))

    state.list = list;
};

export const SET_EMAILIDS = (state, payload) => {
    state.getEmailList = payload.data.data
}
