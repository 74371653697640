import AppModal from '../../components/AppModal'
export default {
  name: 'add-modal',
  components: { AppModal },
  data() {
    return {
    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {
    onClose(){
      this.$emit('onClose')
    }
  }
}
