import SimpleVueValidation from 'simple-vue-validator';
import { mapGetters, mapActions } from "vuex";
import AddAndEditModal from '@/components/Users/AddAndEditModal'
import FilterModal from '@/components/FilterModal'
import DeleteModal from "@/components/DeleteModal";


const Validator = SimpleVueValidation.Validator.create({
  templates: {
    Empty: 'This Field is required.'
  }
});;

export default {
  name: 'FreeUsers',
  components: {
    AddAndEditModal,
    FilterModal,
    DeleteModal
  },
  props: [],
  data() {
    return {
      show_internal_user: 1,
      showAppTable : true,
      enable_status : false,
      json_data: [],
      column_sort : "desc",
      sort_column_name :'',
      sort_column_type :'',
      freeuserlist : [],
      columns: [
        {
          label: 'sl_no',
          field: 'sl_no',
          filterable: false,
        },
        {
          label: 'name',
          field: 'name',
          filterable: false,
        },
        {
          label: 'Last7days',
          field: 'Last7days',
          filterable: false,
        },
        {
          label: 'Last30days',
          field: 'Last30days',
          filterable: false,
        },
        {
          label: 'CurrentMonth',
          field: 'CurrentMonth',
          filterable: false,
        },
        {
          label: 'LastMonth',
          field: 'LastMonth',
          filterable: false,
        },
        {
          label: 'Last90days',
          field: 'Last90days',
          filterable: false,
        },
        {
          label: 'Last365days',
          field: 'Last365days',
          filterable: false,
        },
        {
          label: 'All',
          field: 'All',
          filterable: false,
        },

      ],
      rows1: [],
      tableResultRows: [],
      rows: null,
      perPage: null,
      currentPage: 1,
      users: [],
      getResult: {},
      gamesSearch: '',
      totalCount: '',
      activeTab: 'Week',
      loadMoreStatus: false,
      gamesListTotalCount: {}
    }
  },
  mounted() {
    let params = {
      internal_user : this.show_internal_user,
    }
    this.getUserList(params);
  },
  watch: {
    gamesSearch: function () {
      clearTimeout(this.timeVariable);
      let that = this;
      this.timeVariable = setTimeout(function () {
        that.searchData();
      }, 1000);
    }
  },
  methods: {
    ...mapActions("Games", ["GETGAMES","DOWNLOADGAMESLIST"]),

    downloadUserList(){
      let params = {
        internal_user : this.show_internal_user,
        filter : this.activeTab
      } 
      this.DOWNLOADGAMESLIST(params).then((response) => {
      // console.log('response------11111111111111111',response)
        if (response.status === 200) {
          this.json_data = response.data.data
          // console.log('response.data-------',this.json_data)
          setTimeout(() => {
            this.$refs.fileInput.$el.click();
          }, 800);
        } else {
        }
      })
    },

    showInternalUser(data){
      // console.log('data----->>>>>>>',data.value);
      if(data.value == true){
        this.show_internal_user = 0;
        let params = {
          filter : this.activeTab,
          search: this.gamesSearch,
          internal_user : this.show_internal_user,
        }
        this.getUserList(params);
      }else{
        this.show_internal_user = 1;
        let params = {
          filter : this.activeTab,
          search: this.gamesSearch,
          internal_user : this.show_internal_user,
        }
        this.getUserList(params);
      }
    },

    // SEARCH

    searchData() {
      let params = {
        internal_user : this.show_internal_user,
        filter : this.activeTab,
        search: this.gamesSearch
      }
      this.GETGAMES(params).then((response) => {
        if (response.status === 200) {
          // console.log('response------',response.data.data)
          let responseData = response.data.data;
            this.rows1 = responseData;
            this.gamesListTotalCount = response.data.total
            // this.totalCount = response.data.meta.pagination.total;
            // this.currentPage = response.data.meta.pagination.current_page;
            // this.perPage = response.data.meta.pagination.per_page;
            // console.log('response------',this.totalCount)
            // console.log('response------',this.currentPage)
            // console.log('response------',this.perPage)
        } 
      })
    },
    fnameSorting(column_name){
      // console.log('dataaaaaaaaaa------------->>>>>>>>>',column_name);
      if(this.column_sort == 'asc'){
        this.sort_column_type = 'asc'
          this.sort_column_name = column_name;
          let params = {
            internal_user : this.show_internal_user,
            filter : this.activeTab,
            search : this.gamesSearch,
            column_name : column_name,
            order_by : this.column_sort
          }
            this.getUserList(params);
            this.column_sort = 'desc'    
      }else{
        this.sort_column_type = 'desc'
          this.sort_column_name = column_name;
          let params = {
            internal_user : this.show_internal_user,
            filter : this.activeTab,
            search : this.gamesSearch,
            column_name : column_name,
            order_by : this.column_sort
          }
            this.getUserList(params);
            this.column_sort = 'asc'
      }
    },

    // User List
     
    getUserList(params) {
      // console.log('params===============<>>>>>>>>>>',params);
      if(params){
        this.GETGAMES(params).then((response) => {
          // console.log('response---------->>>>>11111111',response);
          if (response.status === 200) {
            let responseData = response.data.data;
            this.rows1 = responseData;
            this.gamesListTotalCount = response.data.total
            console.log('response------',this.rows1)
            // this.totalCount = response.data.meta.pagination.total;
            // this.currentPage = response.data.meta.pagination.current_page;
            // this.perPage = response.data.meta.pagination.per_page;
            // console.log('response------',this.totalCount)
            // console.log('response------',this.currentPage)
            // console.log('response------',this.perPage)
          }
        })
      }else{
        let params ={
          internal_user : this.show_internal_user,
          column_name : 'week',
          order_by : this.column_sort
        }
        this.GETGAMES(params).then((response) => {
          // console.log('response---------->>>>>11111111',response);
          if (response.status === 200) {
            let responseData = response.data.data;
            this.rows1 = responseData;
            this.gamesListTotalCount = response.data.total
            console.log('response------',this.rows1)
            // this.totalCount = response.data.meta.pagination.total;
            // this.currentPage = response.data.meta.pagination.current_page;
            // this.perPage = response.data.meta.pagination.per_page;
            // console.log('response------',this.totalCount)
            // console.log('response------',this.currentPage)
            // console.log('response------',this.perPage)
          }
        })
      }
    },

    //HANDLE PAGINATION

    handlePagination(val) {
      let params = {
        internal_user : this.show_internal_user,
        page: val,
        ...(
          this.activeTab
            ? { type: this.activeTab }
            : {
              start_date: this.startDate,
              end_date: this.endDate
            }),
        column_name : this.sort_column_name,
        order_by : this.sort_column_type
      }
      window.scrollTo({ top: 0, behavior: 'smooth' })
      this.getUserList(params)
    },

    //HANDLE FILTER TABS

    handleFilter(value) {
      this.activeTab = value;
      let params = {
        internal_user : this.show_internal_user,
        filter : this.activeTab,
        page: 1,
        search : this.gamesSearch,
        column_name : 'game_played_count_count',
        order_by : this.column_sort
      }
      this.getUserList(params)
    },
  }
}
