import api from "@/store/services/api";
import { EventBus } from "@/services/eventBus.js"

export const GETDETAILS = ({ commit }, params) => {
  // EventBus.$emit("loader", true);
  return api.get('dashboard').then(response => {
    // EventBus.$emit("loader", false);
    return response;
  })
}

export const GETDOWNLOADGRAPHDETAILS = ({ commit }, params) => {
  if(params){
      EventBus.$emit("loader", true);
      return api.get(`dashboard-data/downloadGraph?filter=${params}`).then(response => {
      EventBus.$emit("loader", false);
      return response;
  })
}else{
      // EventBus.$emit("loader", true);
      return api.get('dashboard-data/downloadGraph?filter=Week').then(response => {
      // EventBus.$emit("loader", false);
      return response;
    })
  }
}

export const GETUSERGRAPHDETAILS = ({ commit }, params) => {
  if(params){
      EventBus.$emit("loader", true);
      return api.get(`dashboard-data/userGraph?filter=${params}`).then(response => {
      EventBus.$emit("loader", false);
      return response;
    })
  }else{
      // EventBus.$emit("loader", true);
      return api.get('dashboard-data/userGraph?filter=Week').then(response => {
      // EventBus.$emit("loader", false);
      return response;
    })
  }
}

export const GETGAMESGRAPHDETAILS = ({ commit }, params) => {
  if(params){
      EventBus.$emit("loader", true);
      return api.get(`user-game-graph?filter=${params}`).then(response => {
      EventBus.$emit("loader", false);
      return response;
  })
}else{
      EventBus.$emit("loader", true);
      return api.get('user-game-graph?filter=Week').then(response => {
      // EventBus.$emit("loader", false);
      return response;
    })
  }
}

export const GETROUNDSGRAPHDETAILS = ({ commit }, params) => {
  if(params){
      EventBus.$emit("loader", true);
      return api.get(`dashboard/graph/user-details?filter=${params}`).then(response => {
      EventBus.$emit("loader", false);
      return response;
  })
}else{
      // EventBus.$emit("loader", true);
      return api.get('dashboard/graph/user-details?filter=Week').then(response => {
      EventBus.$emit("loader", false);
      return response;
    })
  }
}
// export const GETCONVERSIONFROMTRAILGRAPHDETAILS = ({ commit }, params) => {
//   // EventBus.$emit("loader", true);
//   return api.get('dashboard-data/conversionTrails').then(response => {
//     // EventBus.$emit("loader", false);
//     return response;
//   })
// }

