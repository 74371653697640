import api from "@/store/services/api";
import { EventBus } from "@/services/eventBus.js";

// GET BEEZER BANK LIST 

export const GETBEEZE = ({ commit }, params) => {
  EventBus.$emit("loader", true);
  if (typeof (params) == 'object') {
    return api.get('dashboard/list/beezer-bank-list' , params).then(response => {
      commit("SET_BEEZE_LIST", response.data);
      EventBus.$emit("loader", false);
      return response;
    })
  }
  else {
    return api.get('dashboard/list/beezer-bank-list' + params).then(response => {
      commit("SET_BEEZE_LIST", response.data);
      EventBus.$emit("loader", false);
      return response
    })
  }
}

// GET BEEZER BANK NUMBER OF BETS GRAPH DATA

export const GETBBNOOFBETS = ({ commit }, request) => {
  EventBus.$emit("loader", true);
  return api
    .get("dashboard/graph/beezer-bank-bet?filter=" + request.filter)
    .then(response => {
      EventBus.$emit("loader", false);
      return response;
    });
};

// GET BEEZER BANK TOTAL BET AMOUNT GRAPH DATA

export const GETBBTOTALBETAMOUNT = ({ commit }, request) => {
  EventBus.$emit("loader", true);
  return api
    .get("dashboard/graph/beezer-bank-bet-amount?filter=" + request.filter)
    .then(response => {
      EventBus.$emit("loader", false);
      return response;
    });
};

// GET BEEZER BANK TRANSACTIONS GRAPH DATA

export const GETBBTRANSGRA = ({ commit }, request) => {
  EventBus.$emit("loader", true);
  return api
    .get("dashboard/graph/beezer-bank-transactions?filter=" + request.filter)
    .then(response => {
      EventBus.$emit("loader", false);
      return response;
    });
};

// GET BEEZER BANK BALANCE GRAPH DATA

export const GETBEEZERBALGRA = ({ commit }, request) => {
  EventBus.$emit("loader", true);
  return api
    .get("dashboard/graph/beezer-bank-balance?filter=" + request.filter)
    .then(response => {
      EventBus.$emit("loader", false);
      return response;
    });
};

// GET BEEZER BANK TOTAL AMOUNT TRANSACTIONS GRAPH DATA

export const GETTOTAMTBBTRANSGRA = ({ commit }, request) => {
  EventBus.$emit("loader", true);
  return api
    .get(
      "dashboard/graph/beezer-bank-transactions?amount=yes&filter=" +
        request.filter
    )
    .then(response => {
      EventBus.$emit("loader", false);
      return response;
    });
};

//DOWNLOAD TRANSACTION LIST

export const DOWNLOADTRANSACTIONLIST = ({ commit },params) => {
  EventBus.$emit("loader", true);
  return api.get("download/beezer-transaction",params).then(response => {
    // console.log('response------asdasdasdasd',response)
    EventBus.$emit("loader", false);
    return response;
  });
};
