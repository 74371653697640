<template>
  <Modal class="sendEmailmodal" :id="id" :title="title" @resetState="resetState">
    <template #modal-card-body>
      <div class="form-group">
        <label class="text-primary">To</label>
        <textarea
          class="form-control"
          v-model="getResult.mailIds"
          rows="2"
          id="senders"
          disabled
        ></textarea>
      </div>
      <div class="mt-4" v-for="(data,index) in textEditors" :key="index">
        <div class="form-group">
          <label class="text-primary">Subject</label>
          <input type="text" v-model="mailSubject" class="form-control" id="usr" />
          <small v-if="subjectErr" class="text-danger px-3">Field can't be empty.</small>
        </div>
        <div class="form-group">
          <label class="text-primary">Themes</label>
          <p class="email-theme">
            <input type="radio" v-model="checkedTheme" name="match" id="theme_0" value="0" />
            <label class="theme-label" for="theme_0">
              <img src="@/assets/images/sample.png" />
              <p>Default</p>
            </label>
          </p>
          <!-- <p class="email-theme">
            <input type="radio" v-model="checkedTheme" name="match" id="theme_1" value="1" />
            <label class="theme-label" for="theme_1">
              <img src="@/assets/images/sample.png" />
              <p>Christmas</p>
            </label>
          </p>
          <p class="email-theme">
            <input type="radio" v-model="checkedTheme" name="match" id="theme_2" value="2" />
            <label class="theme-label" for="theme_2">
              <img src="@/assets/images/sample.png" />
              <p>Diwali</p>
            </label>
          </p> -->
          <!-- <p class="email-theme">
            <input type="radio" v-model="checkedTheme" name="match" id="theme_3" value="3" />
            <label class="theme-label" for="theme_3">
              <img src="@/assets/images/sample.png" />
              <p>New Year</p>
            </label>
          </p> -->
        </div>
        <span class="text-primary">{{data.title}}</span>
        <AppToggle
          class="d-none"
          v-model="data.toggle"
          :class="data.model != null ? 'disabled' : ''"
        ></AppToggle>
        <AppTextEditor v-model="mailContent" v-if="data.model != null || data.toggle != false"></AppTextEditor>
        <small v-if="commentErr" class="text-danger px-3">Field can't be empty.</small>
      </div>
    </template>
    <template #modal-card-footer>
      <div class="text-center w-100">
        <b-button size="sm" pill variant="outline-success" class="px-4 py-2" @click="onSubmit">Send</b-button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "../Modal";
import AppToggle from "@/components/AppToggle";
import AppTextEditor from "@/components/AppTextEditor";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "sendmailmodal",
  components: {
    Modal,
    AppTextEditor,
    AppToggle
  },
  props: {
    id: {
      default: "SendMail",
      type: String
    },

    title: {
      default: "Send Mail",
      type: String
    },
    getResult: {
      default: [],
      type: Object
    },
    activeTabName:{
      defaul: 'app',
      type: String
    }
  },
  data() {
    return {
      mailContent: "",
      mailSubject: "",
      activeTab:this.activeTabName,
      subjectErr: false,
      commentErr: false,
      checkedTheme: "",
      textEditors: [
        {
          title: "Comments",
          model: null,
          toggle: true
        }
      ]
    };
  },
  watch: {
    mailContent: function() {
      if (this.mailContent == "") {
        this.commentErr = true;
      } else {
        this.commentErr = false;
      }
    },
    mailSubject: function() {
      if (this.mailSubject == "") {
        this.subjectErr = true;
      } else {
        this.subjectErr = false;
      }
    },
    activeTabName: function(){
      this.activeTab = this.activeTabName
    }
  },
  methods: {
    ...mapActions("Communications", ["SENDMAIL"]),
    onSubmit() {
      let type ;
      if(this.activeTab == 'site'){
        type = 1
      }else{
        type = 0
      }
      // console.log('type----->>>>>',type);
      var params = {
        type:type,
        checked: this.getResult.data.checkedIds,
        not_checked: this.getResult.data.uncheckedIds,
        check_all: this.getResult.check_all,
        communication_type : this.checkedTheme ? this.checkedTheme : '0',
        subject:this.mailSubject,
        message: this.mailContent
      };

      if (this.mailContent != "" && this.mailSubject != "" && this.getResult.checkedIds != "") {
        this.SENDMAIL(params).then(response => {
          if (response.status == 200) {
            this.$notify({
              type: "success",
              title: "Successfull",
              text: response.message
            });
            this.$root.$emit("bv::toggle::modal", "SendMailModal");
            this.mailContent = "";
            this.mailSubject = "";
            setTimeout(() => {
              location.reload();
            }, 600);
          } else {
            this.$notify({
              type: "warn",
              title: "Error",
              text: response.message
            });

            // this.$root.$emit("bv::toggle::modal", "SendMailModal");
            this.$emit("onSubmit");
          }
        });
        this.commentErr = false;
        this.subjectErr = false;
      } else {
        if (this.mailSubject == "") {
          this.subjectErr = true;
        }else if (this.mailContent == "") {
          this.commentErr = true;
        } else {
          this.subjectErr = false;
          this.commentErr = false;
          this.$notify({
              type: "warn",
              title: "Error",
              text: "Mail Ids not found"
            });
        }
      }
    },

    resetState() {
      this.$emit("reset");
    }
  }
};
</script>
<style>
#SendMailModal .modal-dialog.modal-md.modal-dialog-centered {
  max-width: 70% !important;
}
.email-theme {
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 10%;
  margin: 20px 1% 20px 0;
  height: 100px;
  vertical-align: top;
  font-size: 22px;
  text-align: center;
}
.email-theme img {
  width: 100%;
  height: 60px;
}
#theme_0,
#theme_1,
#theme_2,
#theme_3 {
  opacity: 0;
}
input[type="radio"]:checked ~ label {
  opacity: 1;
  /* border: 1px solid green; */
  box-shadow: 0px 0px 11px 2px #7b7b7b;
}
.theme-label {
  cursor: pointer;
}
.theme-label p{
  font-size: 12px;
  font-weight: 800;
  padding: 5px 0px;
  text-align:center;
  margin-bottom: 0
}
</style>
