import { mapGetters, mapActions } from "vuex";
export default {
  name: 'app-userBankTransaction',

  components: {},

  props: [],

  data() {
    return {
      currentPage:1,
      greenSymbol: 'currency_symbol',
      redSymbol: 'currency_symbol_red',
      fields: [
        "date",
        "type",
        "from",
        "to",
        "amount"
      ]
    }
  },
  created() {
  },
  computed: {
    ...mapGetters("Users", ["getUserDetails", "getUserTransactions","getTransactionsPaginationDetails"]),

  },

  methods: {
    ...mapActions("Users",['GETUSERTRANSACTIONLIST']),
    changeImage(val) {
      var user_id = this.getUserDetails.data[0].player_id
      if (user_id == val) {
        return this.greenSymbol
      }
      else {
        return this.redSymbol
      }
    },
    handlePagination(val) {
      let params = {
        page: val,
      }
      window.scrollTo({ top: 0, behavior: 'smooth' })
      this.getUserTransactionData(params)
    },
    getUserTransactionData(params){
      this.GETUSERTRANSACTIONLIST({id: this.$route.query.id, page: params.page}).then((response) => {
        // this.currentPage = response.meta.pagination.current_page
      });
    },
  },
}
