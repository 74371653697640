import api from "@/store/services/api";
import { EventBus } from "@/services/eventBus.js"

// GET DOWNLOAD LIST

export const GETDOWNLOADLIST = ({ commit }, params) => {
  EventBus.$emit("loader", true);
  return api.get('listdownloadfiles',params).then(response => {
    EventBus.$emit("loader", false);
    return response;
  })
}

//DOWNLOAD

export const DOWNLOADFILES = ({ commit },params) => {
  EventBus.$emit("loader", true);
  return api.get("downloadfiles?file_name="+params).then(response => {
    // console.log('response------asdasdasdasd',response)
    EventBus.$emit("loader", false);
    return response;
  });
};

//DELETE

export const DELETEFILES = ({ commit },params) => {
  EventBus.$emit("loader", true);
  return api.get("deletedownloadfiles?file_name="+params).then(response => {
    // console.log('response------asdasdasdasd',response)
    EventBus.$emit("loader", false);
    return response;
  });
};
