import SimpleVueValidation from 'simple-vue-validator';
import { mapGetters, mapActions } from "vuex";
import AddAndEditModal from '@/components/Users/AddAndEditModal'
import FilterModal from '@/components/FilterModal'
import DeleteModal from "@/components/DeleteModal";


const Validator = SimpleVueValidation.Validator.create({
  templates: {
    Empty: 'This Field is required.'
  }
});;

export default {
  name: 'FreeUsers',
  components: {
    AddAndEditModal,
    FilterModal,
    DeleteModal
  },
  props: [],
  data() {
    return {
      allSelected: false,
      checkAllStatus: false,
      showAppTable : true,
      enable_status : false,
      json_data: [],
      column_sort : "asc",
      sort_column_name :'',
      sort_column_type :'',
      freeuserlist : [],
      columns: [
        // {
        //   label: 'Sl.No',
        //   field: 'sNo',
        //   filterable: false,
        // },
        {
          label: 'id',
          field: 'id',
          filterable: false,
        },
        {
          label: 'user_name',
          field: 'user_name',
          filterable: false,
        },
        {
          label: 'email',
          field: 'email',
          filterable: false,
        },
        {
          label: 'apple_email_id',
          field: 'apple_email_id',
          filterable: true,
        },
        {
          label: 'apple_id',
          field: 'apple_id',
          filterable: true,
        },
        {
          label: 'Updated_at',
          field: 'Updated_at',
          filterable: true,
        },
        {
          label: 'is_register',
          field: 'is_register',
          filterable: false,
        },
        {
          label: 'notes',
          field: 'notes',
          filterable: false,
        },
        {
          label: 'delete',
          field: 'delete',
          filterable: false,
        },
      ],
      rows1: [],
      tableResultRows: [],
      rows: null,
      perPage: null,
      currentPage: 1,
      users: [],
      timeVariable: null,
      sendMailModalId: "SendMailModal",
      activeTab: 'app',
      startDate: "",
      endDate: "",
      selectedRow: null,
      getResult: {},
      subscriberSearch: '',
      view: 2,
      totalCount: '',
      editId : '',
      editNotes: '',
      deleteId : '',
      loadMoreStatus: false,
      free_user_email_id : '',
      free_user_notes:'',
      edit_email_id : '',
      edit_free_user_notes: '',
      getMailIds: '',
      deleteModalId: "UserDetailDeleteModal",
    }
  },
  validators: {
    free_user_email_id: function(value) {
      return Validator.value(value)
        .required("This Field is mandatory")
        .email("Enter the Valid Email ID");
    },
    edit_email_id: function(value) {
      return Validator.value(value)
        .required("This Field is mandatory")
        .email("Enter the Valid Email ID");
    }
  },
  computed: {
  },
  watch: {
    activeTab: function () {
    }
  },
  mounted() {
    this.getUserList();
  },
  watch: {
    subscriberSearch: function () {
      clearTimeout(this.timeVariable);
      let that = this;
      this.timeVariable = setTimeout(function () {
        that.searchData();
      }, 1000);
    }
  },
  methods: {
    ...mapActions("FreeUsers", ["GETUSERS",'ADDUSER', "EDITUSER","DELETEUSER","DOWNLOADFREEUSERLIST"]),

    //Toggle Modal

    editModal(userid,notes) {
      this.editId = userid
      this.editNotes = notes
      this.$refs['edit-modal'].show()
      this.edit_email_id = this.rows1.find(data => data.id === userid).email
      this.edit_free_user_notes = this.rows1.find(data => data.notes === notes).notes
    },
    deleteModal(userid) {
      this.deleteId = userid
      this.$refs['delete-modal'].show()
    },
    addModal() {
      this.$refs['add-modal'].show()
    },
    cancelModal(data){
      this.$refs[data].hide()
    },  
    onSubmit() {
      this.$validate().then(success => {
        // let {
        //   free_user_email_id,
        //   free_user_notes
        // } = this;
        let payload = {
          email: this.free_user_email_id,
          notes: this.free_user_notes
        };
        if (success) {
          let payload = {
            email: free_user_email_id,
            notes: free_user_notes
          };
        }
      this.addUser(payload);
      });
      
    },

    editonSubmit() {
      this.$validate().then(success => {
        let {
          edit_email_id,
        } = this;
        if (success) {
          let payload = {
            email: edit_email_id,
          };
        }
      this.editUser();
      });
      
    },

    addUser(data){
      console.log('data-----',data)
      this.$refs['add-modal'].hide()
      let params = {
        email : data.email,
        notes : data.notes
      }
      this.ADDUSER(params).then((response) => {
        if (response.status == 200) {
          this.$notify({
            type: "success",
            title: "Successfull",
            text: response.message
          });
          this.getUserList();
        }else {
          // console.log(response.body,'rrrrrrrrrrrrrrrrr')
          this.$notify({
            type: "warn",
            title: "Error",
            text: response.body.message
          });
        }
      });
    },

    editUser(){
      this.$refs['edit-modal'].hide()
      let params = {
        id : this.editId,
        email : this.edit_email_id,
        notes : this.edit_free_user_notes
      }
      this.EDITUSER(params).then((response) => {
        if (response.status == 200) {
          this.$notify({
            type: "success",
            title: "Successfull",
            text: response.message
          });
          this.getUserList();
        }else {
          this.$notify({
            type: "warn",
            title: "Error",
            text: response.message
          });
        }
      });
    },

    deleteUser(){
        this.$refs['delete-modal'].hide()
        let params = {
          id : [this.deleteId]
        }
        this.DELETEUSER(params).then((response) => {
          if (response.status == 200) {
            this.$notify({
              type: "success",
              title: "Successfull",
              text: response.message
            });
            this.getUserList();
          }else {
            this.$notify({
              type: "warn",
              title: "Error",
              text: response.message
            });
          }
        });
    },

    downloadUserList(){
      this.DOWNLOADFREEUSERLIST().then((response) => {
      // console.log('response------11111111111111111',response)
        if (response.status === 200) {
          // window.open(
          //   response.url,
          //   "_blank"
          // );
          this.json_data = response.data.data
          // console.log('response.data-------',this.json_data)
          setTimeout(() => {
            this.$refs.fileInput.$el.click();
          }, 800);
        } else {
        }
      })
    },

    // SEARCH

    searchData() {
      let params = {
        search: this.subscriberSearch
      }
      this.GETUSERS(params).then((response) => {
        if (response.status === 200) {
          let responseData = response.data.data;
            this.rows1 = responseData;
            // console.log('response------',this.rows1)
            this.totalCount = response.data.meta.pagination.total;
            this.currentPage = response.data.meta.pagination.current_page;
            this.perPage = response.data.meta.pagination.per_page;
            // console.log('response------',this.totalCount)
            // console.log('response------',this.currentPage)
            // console.log('response------',this.perPage)
        } 
      })
    },
    fnameSorting(column_name){
      // console.log('dataaaaaaaaaa------------->>>>>>>>>',column_name);
      if(this.column_sort == 'asc'){
        this.sort_column_type = 'asc'
          this.sort_column_name = column_name;
          let params = {
            column_name : column_name,
            order_by : this.column_sort
          }
            this.getUserList(params);
            this.column_sort = 'desc'    
      }else{
        this.sort_column_type = 'desc'
          this.sort_column_name = column_name;
          let params = {
            column_name : column_name,
            order_by : this.column_sort
          }
            this.getUserList(params);
            this.column_sort = 'asc'
      }
    },

    // User List
     
    getUserList(params) {
      // console.log('params===============<>>>>>>>>>>',params);
      if(params){
        this.GETUSERS(params).then((response) => {
          // console.log('response---------->>>>>11111111',response);
          if (response.status === 200) {
            let responseData = response.data.data;
            this.rows1 = responseData;
            // console.log('response------',this.rows1)
            this.totalCount = response.data.meta.pagination.total;
            this.currentPage = response.data.meta.pagination.current_page;
            this.perPage = response.data.meta.pagination.per_page;
            // console.log('response------',this.totalCount)
            // console.log('response------',this.currentPage)
            // console.log('response------',this.perPage)
          }
        })
      }else{
        this.GETUSERS().then((response) => {
          // console.log('response---------->>>>>11111111',response);
          if (response.status === 200) {
            let responseData = response.data.data;
            this.rows1 = responseData;
            // console.log('response------',this.rows1)
            this.totalCount = response.data.meta.pagination.total;
            this.currentPage = response.data.meta.pagination.current_page;
            this.perPage = response.data.meta.pagination.per_page;
            // console.log('response------',this.totalCount)
            // console.log('response------',this.currentPage)
            // console.log('response------',this.perPage)
          }
        })
      }
    },

    // Toggle SelectAll

    toggleSelectAll(event) {
      this.checkAllStatus = event.target.checked
      this.allSelected = !this.allSelected;

      this.tableResultRows.forEach(row => {
        if (this.allSelected) {
          // this.allSelected = true
          row.selected = true;
          this.checkeddData.checkedIds.push(row.id)
        } else {
          // this.allSelected = true            
          row.selected = false;
          this.checkeddData.checkedIds = []
        }
      })
    },

    //HANDLE PAGINATION

    handlePagination(val) {
      let params = {
        page: val,
        ...(
          this.activeTab
            ? { type: this.activeTab }
            : {
              start_date: this.startDate,
              end_date: this.endDate
            }),
        column_name : this.sort_column_name,
        order_by : this.sort_column_type
      }
      window.scrollTo({ top: 0, behavior: 'smooth' })
      this.getUserList(params)
    },

    //HANDLE FILTER TABS

    handleFilter(value = 'app', event) {
      this.allSelected = false
      document.getElementById("checkBoxInput").checked = false;
      this.checkeddData.checkedIds = [],
        this.checkeddData.uncheckedIds = []
      this.activeTab = value
      this.startDate = ''
      this.checkAllStatus = false
      this.endDate = ''
      this.subscriberSearch = ''
      let params = {
        type: value,
        page: 1,
      }
      this.getUserList(params)
    },
  }
}
