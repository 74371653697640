export default {
  name: 'RoundsTable',
  components: {},
  props: [
    'items',
    'striped',
    'rowClick',
    'totalRows',
    'pageCount'
  ],
  data() {
    return {
      fields: [
        {
          key: 'course_name',
          sortable: true
        },
        {
          key: 'country',
          sortable: true
        },
        {
          key: 'date',
          sortable: true
        },
        {
          key: 'players',
          sortable: false
        },
        {
          key: 'number_of_groups',
          sortable: true
        },
        {
          key: 'number_of_players',
          sortable: true
        },
        {
          key: 'Email id',
          sortable: false
        },
        {
          key: 'apple_email_id',
          sortable: false
        },
        {
          key: 'apple_id',
          sortable: false
        },
        {
          key: 'games',
          sortable: false,
        },
        {
          key: 'round_duration',
          sortable: true,
        },
        {
          key: 'Total Bets',
          sortable: true,
        },
        {
          key: 'Holes',
          sortable: true,
        },
        {
          key: 'Watch',
          sortable: false,
        },
        {
          key: 'status',
          sortable: true,
        },
        {
          key:'invite',
          sortable: false
        }
      ],
    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {
    roundSort(data){
      // console.log('data------->>>>>',data);
      this.$emit('roundsortingdata',data)
    }
  }
}
