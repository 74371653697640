import AppInput from '@/components/AppInput'
import AppTextEditor from '@/components/AppTextEditor'
import AppToggle from '@/components/AppToggle'
import { mapGetters, mapActions } from "vuex";

export default {
  name: 'details-form',
  components: {
    AppInput,
    AppTextEditor,
    AppToggle
  },
  props: ['value'],
  data() {
    return {
      activetab: '1' ,
      gameDetail: '',
      cmsDetailsForm : {
        homeTitle:'',
        homeDesc:'',
        gameTitle:'',
        gameDesc:'',
        beezerBankTitle:'',
        beezerBankDesc:'',
        scorecardTitle:'',
        scorecardDesc:'',
        golfCoursesTitle:'',
        golfCoursesDesc:'',
        insightsTitle:'',
        insightsDesc:'',
        gpsTitle:'',
        gpsDesc:'',
        timerTitle:'',
        timerDesc:'',
        aboutUsTitle:'',
        aboutUsDesc : ''
      },
    }
  },
  computed: {
    ...mapGetters("Cms", ["getcmsDetails","cmsDetails"])

  },
  mounted() {
      this.getCmsData();
  },
  methods: {
    ...mapActions("Cms", ["GET_CMS_DETAILS",'CMS_DETAILS']),
    getCmsData() {
      this.GET_CMS_DETAILS(this.$route.query.id).then(() => {
        let that = this
        var arr = this.getcmsDetails.data.data;
        that.cmsDetailsForm.homeTitle = arr[0].header;
        that.cmsDetailsForm.homeDesc = arr[0].body;
        that.cmsDetailsForm.gameTitle = arr[1].header;
        that.cmsDetailsForm.gameDesc = arr[1].body;
        that.cmsDetailsForm.beezerBankTitle = arr[2].header;
        that.cmsDetailsForm.beezerBankDesc = arr[2].body;
        that.cmsDetailsForm.scorecardTitle = arr[3].header;
        that.cmsDetailsForm.scorecardDesc = arr[3].body;
        that.cmsDetailsForm.golfCoursesTitle = arr[4].header;
        that.cmsDetailsForm.golfCoursesDesc = arr[4].body;
        that.cmsDetailsForm.insightsTitle = arr[5].header;
        that.cmsDetailsForm.insightsDesc = arr[5].body;
        that.cmsDetailsForm.gpsTitle = arr[6].header;
        that.cmsDetailsForm.gpsDesc = arr[6].body;
        that.cmsDetailsForm.timerTitle = arr[7].header;
        that.cmsDetailsForm.timerDesc = arr[7].body;
        that.cmsDetailsForm.aboutUsTitle = arr[8].header;
        that.cmsDetailsForm.aboutUsDesc = arr[8].body;
      });
    }, 
    updateCmsDetails(cmsDetailsForm) {
  
      var cmsData = {
        data:[{
          section_id:'1',
          sub_section_id:'',
          header:cmsDetailsForm.homeTitle,
          body:cmsDetailsForm.homeDesc
        },{
          section_id:'3',
          sub_section_id:'',
          header:cmsDetailsForm.gameTitle,
          body:cmsDetailsForm.gameDesc
        },{
          section_id:'2',
          sub_section_id:'1',
          header:cmsDetailsForm.beezerBankTitle,
          body:cmsDetailsForm.beezerBankDesc
        },{
          section_id:'2',
          sub_section_id:'2',
          header:cmsDetailsForm.scorecardTitle,
          body:cmsDetailsForm.scorecardDesc
        },{
          section_id:'2',
          sub_section_id:'3',
          header:cmsDetailsForm.golfCoursesTitle,
          body:cmsDetailsForm.golfCoursesDesc
        },{
          section_id:'2',
          sub_section_id:'4',
          header:cmsDetailsForm.insightsTitle,
          body:cmsDetailsForm.insightsDesc
        },{
          section_id:'2',
          sub_section_id:'5',
          header:cmsDetailsForm.gpsTitle,
          body:cmsDetailsForm.gpsDesc
        },{
          section_id:'2',
          sub_section_id:'6',
          header:cmsDetailsForm.timerTitle,
          body:cmsDetailsForm.timerDesc
        },{
          section_id:'4',
          sub_section_id:'',
          header:cmsDetailsForm.aboutUsTitle,
          body:cmsDetailsForm.aboutUsDesc
        }]
    }
      this.CMS_DETAILS(cmsData)
        .then(() => {
          if (this.cmsDetails.status == 200) {
            this.$notify({
              type: 'success',
              title: 'Successfull',
              text: "Successfully Updated"
            })
            this.$router.push({ path: 'Cms' })
          }
          else {
            this.$notify({
              type: 'warn',
              title: 'Error',
              text: this.cmsDetails.message
            })
          }
        })
    },
  }
}
