export const SET_BEEZE_LIST = (state, payload) => {
    let response = payload.data
    let pagination = payload.meta.pagination;
    let slno = (pagination.current_page-1)*pagination.per_page;
    const list = response.map(data => ({
        "S.No":++slno,
        // game: data.id_course,
        // match_type: data.course_name,
        // bet_type: data.rounds,
        email: data.email.join(',') ? data.email.join(',') : '-',
        apple_email: data.apple_email_id.join(',') ? data.apple_email_id.join(',') :'' ,
        apple_id: data.apple_id.join(',') ? data.apple_id.join(',') :'' ,
        from: (data.player_from_first_name ? data.player_from_first_name :'' )+' '+(data.player_from_last_name ? data.player_from_last_name : ''),
        to: (data.player_to_first_name ? data.player_to_first_name : '') +' '+(data.player_to_last_name ? data.player_to_last_name : ''),
        amount: data.amount ? data.amount : '0',
        date: data.date ? data.date : '-'
    }))
    state.list = list;
};

