import Highcharts from 'highcharts'
import {mapActions, mapGetters} from "vuex";


export default {
  name: 'UsersGraph',
  props: [
    'items',
    'striped',
    'rowClick',
    'totalRows',
    'pageCount',
    'fields'
  ],
  created(){
    this.graphData();
    this.getUserGraphFun();
  },
  data() {
    return {
      activeTab:'Week',
      xAxis:[],
      chartOptions:{
        series: [{
          name: 'Credit',
          data: []
        }]
      }
    }
  },
  computed: {
    ...mapGetters("Users", ["getUserGraph"])

  },
  watch:{
    activeTab(){
      this.getUserGraphFun();
    }
  },
  methods: {
    ...mapActions("Users", ["GETUSERGRAPH"]),

    changeTab(tab) {
      this.activeTab = tab;
      this.xAxis = [];
    },
    getUserGraphFun() {
      this.GETUSERGRAPH({filter:this.activeTab}).then(() => {
        let response = Object.assign({}, this.getUserGraph);
        this.xAxis = response.date;
        this.chartOptions.series[0].data = response.count;
        this.graphData();
      });
    },
    graphData(){
      Highcharts.setOptions({
        chart: {
          type: 'column'
        },
        title: {
          text: 'Amount Spent'
        },
        legend: {
          layout: 'vertical',
          align: 'left',
          verticalAlign: 'top',
          x: 150,
          y: 100,
          floating: true,
          borderWidth: 1,
          backgroundColor:
              Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF'
        },
        xAxis: {
          categories: this.xAxis,
          plotBands: [{ // visualize the weekend
            // from: 4.5,
            // to: 6.5,
            // color: 'rgba(68, 170, 213, .2)'
          }]
        },
        yAxis: {
          title: {
            text: 'Amount (in $)'
          },
        },
        tooltip: {
          shared: true,
          valueSuffix: '',
          valuePrefix: '$'
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          areaspline: {
            fillOpacity: 0.5
          }
        },

      })
    }
  }
}
