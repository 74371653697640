import { render, staticRenderFns } from "./Communications.html?vue&type=template&id=1272b0c2&scoped=true&"
import script from "./Communications.js?vue&type=script&lang=js&"
export * from "./Communications.js?vue&type=script&lang=js&"
import style0 from "./Communications.scss?vue&type=style&index=0&id=1272b0c2&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1272b0c2",
  null
  
)

export default component.exports