import api from "@/store/services/api";
import { EventBus } from "@/services/eventBus.js"

// GET ROUNDS LIST

export const GETROUNDS = ({ commit }, params) => {
  EventBus.$emit("loader", true);
  return api.get('admin/rounds', params).then(response => {
    commit("SET_ROUND_LIST", response.data);
    EventBus.$emit("loader", false);
    return response;
  })
}

//DOWNLOAD ROUNDS LIST

export const DOWNLOADROUNDSLIST = ({ commit },params) => {
  EventBus.$emit("loader", true);
  return api.get("download/round",params).then(response => {
    // console.log('response------asdasdasdasd',response)
    EventBus.$emit("loader", false);
    return response;
  });
};

export const FETCHGAMELIST = ({ commit }, params) => {
  EventBus.$emit("loader", true);
  return api.get('games', params).then(response => {
    commit("SETGAMELIST", response.data);
    EventBus.$emit("loader", false);
    return response;
  })
}
