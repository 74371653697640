import AppLogo from '@/components/AppLogo'
import { mapActions } from "vuex";
import LoaderDiv from "@/components/loader";
export default {
  name: 'login',

  components: {
    AppLogo,
    LoaderDiv
  },

  data() {
    return {
      username: '',
      password: ''
    }
  },
  computed: {
    loginfdsfd() {
      return this.loginDetails
    }
  },
  created() {
    localStorage.clear()
  },
  mounted() {

  },
  methods: {
    ...mapActions("Login", ['LOGIN_DETAILS']),
    handleSubmit(username, password) {
      var data = {
        'email': username,
        'password': password
      }
      this.LOGIN_DETAILS(data).then((response) => {
        if (response.status == 200) {
          this.$notify({
            type: 'success',
            title: 'Successfull',
            text: response.message
          })
          this.$router.push({ path: '/' })
        }
        else {
          this.$notify({
            type: 'warn',
            title: 'Error',
            text: response.message
          })
          this.username = '',
            this.password = ''
        }
      })
    }
  }
}
