import api from "@/store/services/api";
import { EventBus } from "@/services/eventBus.js"

// GET COURSES LIST

export const GETCOURSES = ({ commit }, params) => {
  EventBus.$emit("loader", true);
  return api.get('admin/courses', params).then(response => {
    commit("SET_COURSE_LIST", response.data);
    EventBus.$emit("loader", false);
    return response
  })
}

//DOWNLOAD COURSE LIST

export const DOWNLOADCOURSELIST = ({ commit },params) => {
  EventBus.$emit("loader", true);
  return api.get("download/course",params).then(response => {
    // console.log('response------asdasdasdasd',response)
    EventBus.$emit("loader", false);
    return response;
  });
};
