import AppUserRoundsTab from "@/components/AppUserRoundsTab";
import AppUserRoundsTabGra from "@/components/AppUserRoundsGra";
import AppUserGamesPlayedGra from "@/components/AppUserGamesPlayedGra";
import AppUserBankTransactionTab from "@/components/AppUserBankTransactionTab";
import AppUserBankTransactionTabGra from "@/components/AppUserBbTransactionGra";
import AppUserBankBalanceGra from "@/components/AppUserBbBalanceGra";
import AppUserBankBalance from "@/components/AppUserBankBalance";
import AppUserGamesPlayed from "@/components/AppUserGamesPlayed";

import { mapGetters, mapActions } from "vuex";
import EditModal from "@/components/UserDetails/EditModal";
import DeleteModal from "@/components/DeleteModal";
// import { getUserRoundsGra } from "../../store/Users/getters";

export default {
  name: "user-details",
  components: {
    AppUserRoundsTab,
    AppUserRoundsTabGra,
    AppUserGamesPlayedGra,
    AppUserBankTransactionTab,
    AppUserBankTransactionTabGra,
    AppUserBankBalance,
    AppUserBankBalanceGra,
    // getUserRoundsGra,
    AppUserGamesPlayed,
    EditModal,
    DeleteModal
  },
  props: [],
  data() {
    return {
      selectedComponent: "AppUserRoundsTab",
      rows: 5,
      perPage: 3,
      currentPage: 1,
      userDetails: {},
      originalUserDetails: {},
      editModalId: "UserDetailEditModal",
      deleteModalId: "UserDetailDeleteModal",
      view: 1,
      filter: "Week",
      data1:['Game1','game2','Game1','game2','Game1','game2','Game1','game2','Game1','game2','Game1','game2','Game1','game2','Game1','game2','Game1','game2','Game1','game2'],
      data2:[1,23,63,65,45,45,45,6,52,70,1,23,63,65,45,45,45,6,52,70,]
    };
  },
  computed: {
    ...mapGetters("Users", [
      "getUserDetails",
      "getUserBalance",
    ])
  },
  watch: {
    view(newVal, oldVal) {
      if (newVal == 2 && this.selectedComponent == "AppUserGamesPlayed") {
        this.selectedComponent = "AppUserRoundsTab";
      }
      this.reflectChange();
    },
    selectedComponent() {
      this.reflectChange();
    },
    filter:function(){
    }
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.getUserDetailsData();
    this.getUserRoundsFunc();
  },
  methods: {
    ...mapActions("Users", [
      "GETUSERDETAILS",
      "DELETEUSER",
      "EDITUSER",
      "GETUSERGRAPH",
      "GETUSERBBTRANSGRAPH",
      "GETUSERBBBALANCEGRAPH",
      "GETUSERGAMEPLAYEDGRAPH",
      "RESETPASSWORD",
      "GETUSERROUNDSLIST",
      "GETUSERTRANSACTIONLIST",
      "VERIFYEMAIL"
    ]),

    // CHANGE USER DATA TABS

    changeTab(name) {
      this.selectedComponent = name;
      this.filter = "Week"        
    },

    // FILTER

    changeFilter(filter){
      this.filter = filter;
      this.selectedComponent === "AppUserRoundsTab"
        ? this.getUserRoundsGraFunc()
        : "";
      this.selectedComponent === "AppUserBankTransactionTab"
        ? this.getUserBbTransactionGraFunc()
        : "";
      this.selectedComponent === "AppUserBankBalance"
        ? this.getUserBbBalanceGraFunc()
        : "";
      this.selectedComponent === "AppUserGamesPlayed"
        ? this.AppUserGamesPlayedGraFunc()
        : "";  
    },

    changeFilter1(filter){
      this.filter = filter;
      this.selectedComponent === "AppUserRoundsTab"
        ? this.getUserRoundsGraFunc()
        : "";
      this.selectedComponent === "AppUserBankTransactionTab"
        ? this.getUserBbTransactionGraFunc()
        : "";
    },


    // EDIT USER DETAILS

    editUser(body) {
      this.toggleModal(this.addModalId);
      this.EDITUSER({ body, reloadUserList: false }).then((response) => {
        if (response.status == 200) {
          this.getUserDetailsData();
          this.$notify({
            type: "success",
            title: "Successfull",
            text: response.message
          });
        } else {
          this.$notify({
            type: "warn",
            title: "Error",
            text: response.message
          });
        }
      });
    },
    verifyEmail(body) {
      // console.log('body------',body)
      this.toggleModal(this.addModalId);
      this.VERIFYEMAIL({ body, reloadUserList: false }).then((response) => {
        // console.log('response----datea------',response);
        if (response.status == 200) {
          this.getUserDetailsData();
          this.$notify({
            type: "success",
            title: "Successfull",
            text: response.message
          });
        } else {
          this.$notify({
            type: "warn",
            title: "Error",
            text: response.message
          });
        }
      });
    },

    reflectChange() {
      if (this.view == 1) {
        this.selectedComponent === "AppUserRoundsTab"
          ? this.getUserRoundsFunc()
          : "";
        this.selectedComponent === "AppUserBankTransactionTab"
          ? this.getUserBbTransactionFunc()
          : ""; 
      }
      if (this.view == 2) {
        this.selectedComponent === "AppUserRoundsTab"
          ? this.getUserRoundsGraFunc()
          : "";
        this.selectedComponent === "AppUserBankTransactionTab"
          ? this.getUserBbTransactionGraFunc()
          : "";
        this.selectedComponent === "AppUserBankBalance"
          ? this.getUserBbBalanceGraFunc()
          : "";
        this.selectedComponent === "AppUserGamesPlayed"
          ? this.AppUserGamesPlayedGraFunc()
          : "";  
      }
    },

    // TOGGLE MODAL 

    toggleModal(modalId = "UserDetailEditModal") {
      this.userDetails = Object.assign({}, this.originalUserDetails);
      this.$root.$emit("bv::toggle::modal", modalId);
    },

    // DELETE USER DETAILS

    deleteUser() {
      let params = {
        user_id: this.$route.query.id
      };
      this.toggleModal(this.deleteModalId);
      this.DELETEUSER({ params }).then((response)=>{
        if (response.status == 200) {
          this.$notify({
            type: 'success',
            title: 'Successfull',
            text: response.message
          })
        }
        else {
          this.$notify({
            type: 'warn',
            title: 'Error',
            text: response.message
          })
        }
      })
      this.$router.push({ name: "Users" });
    },

    // GET USER ROUNDS GRAPH DATA

    getUserRoundsGraFunc() {
      this.GETUSERGRAPH({ id: this.$route.query.id, filter: this.filter }).then(
        (response) => {
          this.$refs.AppUserRoundsTabGra.UserDetailsRoundsGraphChange({
            xAxis: response.data.data.date,
            yAxis: response.data.data.count,
            filterDate : response.data.data.filter_date
          });
        }
      );
    },

    // GET USER BEEZERBANK TRANSACTION GRAPH DATA

    getUserBbTransactionGraFunc() {
      this.GETUSERBBTRANSGRAPH({
        id: this.$route.query.id,
        filter: this.filter
      }).then((response) => {
        this.$refs.AppUserBankTransactionTabGra.UserDetailsBbTransactionGraphChange({
          xAxis: response.data.data.date,
          yAxis: response.data.data.count,
          filterDate : response.data.data.filter_date
        });
      });
    },

    
    AppUserGamesPlayedGraFunc() {
      
      this.GETUSERGAMEPLAYEDGRAPH({ id: this.$route.query.id, filter: this.filter }).then(
        (response) => {
          this.$refs.AppUserGamesPlayedGra.UserDetailsGamesPlayedChanges({
            xAxis: response.data.data.game,
            yAxis: response.data.data.count,
            filterDate : response.data.data.filter_date
          });
        }
      );
    },

    // GET USER BEEZERBANK BALANCE GRAPH DATA

    getUserBbBalanceGraFunc() {
      this.GETUSERBBBALANCEGRAPH({
        id: this.$route.query.id,
        filter: this.filter
      }).then((response) => {
        this.$refs.AppUserBankBalanceGra.UserDetailsBbBalanceGraphChange({
          xAxis: response.data.data.date,
          yAxis: response.data.data.count,
          filterDate : response.data.data.filter_date
        })
        });
    },

    // GET USER DETAILS

    getUserDetailsData() {
      this.GETUSERDETAILS(this.$route.query.id).then(() => {
          this.originalUserDetails = Object.assign(
            {},
            this.getUserDetails.data[0]
            );
            this.userDetails = Object.assign({}, this.getUserDetails.data[0]);
      });
    },

    getUserRoundsFunc(){
      this.GETUSERROUNDSLIST({id: this.$route.query.id, page: this.currentPage}).then((response) => {
      });
    },

    getUserBbTransactionFunc(){
      this.GETUSERTRANSACTIONLIST({id: this.$route.query.id, page: this.currentPage}).then((response) => {
      });
    },


    // RESET PASSWORD

    resetPassword() {
      let params = {
        user_id: this.$route.query.id
      };
      this.RESETPASSWORD(params).then((response) => {
        if (response.status == 200) {
          this.$notify({
            type: "success",
            title: "Successfull",
            text: response.message
          });
        }else {
          this.$notify({
            type: "warn",
            title: "Error",
            text: response.message
          });
        }
        this.$refs.closeModalIcon.click()
        // $bvModal.hide('resetPasswordModal');
      });
    }
  }
};
