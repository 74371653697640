import api from "@/store/services/api";
import { EventBus } from "@/services/eventBus.js"
import Vue from 'vue'

// GET USER LIST 

export const GETUSERS = ({ commit }, params) => {
  EventBus.$emit("loader", true);
  if (typeof (params) == 'object') {
    return api.get('users?filter=', params).then(response => {
      commit("SET_USERS_LIST", response.data);
      EventBus.$emit("loader", false);
      console.log(' this.getUsersData11111-----', response)
      return response;
    })
  }
  else {
    return api.get('users?filter=' + params).then(response => {
      commit("SET_USERS_LIST", response.data);
      EventBus.$emit("loader", false);
      console.log(' this.getUsersData222-----', response)
      return response;
    })
  }
}

// ADD NEW USER  

export const ADDNEWUSER = ({ commit, dispatch }, body) => {
  EventBus.$emit("loader", true);
  return api.post('users', body).then(response => {
    EventBus.$emit("loader", false);
    // GETUSERS({ commit, dispatch })
    return response
  })
}

// EDIT USER 

export const EDITUSER = ({ commit, dispatch }, { body, reloadUserList = true }) => {
  EventBus.$emit("loader", true);
  return api.put('users', body).then(response => {
    if (reloadUserList){
      console.log('commit ',commit);
      console.log('dispatch ',dispatch);
      GETUSERS({ commit, dispatch })
      return response;
    }
    else
      GETUSERDETAILS({ commit, dispatch })
    EventBus.$emit("loader", false);
    return response;
  })
}

// VERIFY USER 

export const VERIFYEMAIL = ({ commit, dispatch }, { body, reloadUserList = true }) => {
  EventBus.$emit("loader", true);
  return api.post('users/verify', body).then(response => {
    if (reloadUserList){
      // GETUSERS({ commit, dispatch })
      return response;
    }
    else
      GETUSERDETAILS({ commit, dispatch })
    EventBus.$emit("loader", false);
    return response;
  })
}

// DELETE USER 

export const DELETEUSER = ({ commit, dispatch }, { params, reloadUserList = true }) => {
  EventBus.$emit("loader", true);
  return api.delete('users', params).then(response => {
    if (response.status === 200) {
      EventBus.$emit("loader", false);
      if (reloadUserList){
        // GETUSERS({ commit, dispatch })
      }
      else
        GETUSERDETAILS({ commit, dispatch })
      return response;
    } else {

    }
  })
}

// GETTING USER DETAILS FROM ID 

export const GETUSERDETAILS = ({
  commit
}, request) => {
  EventBus.$emit("loader", true);
  return api.get('users/' + request).then(response => {
    commit("SET_DETAILS", response.data);
    EventBus.$emit("loader", false);
  })
}

// GETTING USER DETAILS FROM ID 

export const GETUSERROUNDSLIST = ({
  commit
}, request) => {
  EventBus.$emit("loader", true);
  return api.get('users/rounds?id=' + request.id + '&page=' + request.page ).then(response => {
    commit("SET_USERROUNDSLIST", response.data);
    EventBus.$emit("loader", false);
    return response
  })
}

export const GETUSERTRANSACTIONLIST = ({
  commit
}, request) => {
  EventBus.$emit("loader", true);
  return api.get('users/transactions?id=' + request.id + '&page=' + request.page).then(response => {
    commit("SET_USERRTRANSACTIONLIST", response.data);
    EventBus.$emit("loader", false);
    return response
  })
}

// GET USER ROUNDS GRAPH DATA 

export const GETUSERGRAPH = ({ commit }, request) => {
  EventBus.$emit("loader", true);
  return api.get('dashboard/graph/user-details?user_id=' + request.id + '&filter=' + request.filter).then(response => {
    EventBus.$emit("loader", false);
    return response
  })
}

// GET USER BEEZER BANK TRANSACTION GRAPH DATA 

export const GETUSERBBTRANSGRAPH = ({
  commit
}, request) => {
  EventBus.$emit("loader", true);
  return api.get('dashboard/graph/beezer-bank-transactions?user_id=' + request.id + '&filter=' + request.filter).then(response => {
    EventBus.$emit("loader", false);
    return response
  })
}

// GET USER BEEZER BANK BALANCE GRAPH DATA 

export const GETUSERBBBALANCEGRAPH = ({
  commit
}, request) => {
  EventBus.$emit("loader", true);
  return api.get('dashboard/graph/beezer-bank-balance?user_id=' + request.id + '&filter=' + request.filter).then(response => {
    EventBus.$emit("loader", false);
    return response;
  })
}

// GET USER GAME PLAYED GRAPH DATA 

export const GETUSERGAMEPLAYEDGRAPH = ({
  commit
}, request) => {
  EventBus.$emit("loader", true);
  return api.get('user-game-graph?user_id=' + request.id + '&filter=' + request.filter).then(response => {
    EventBus.$emit("loader", false);
    return response;
  })
}

// GET USER GAME DETAILS 

export const GETUSERGAMEDETAILS = ({
  commit
}, request) => {
  EventBus.$emit("loader", true);
  return api.get('usergames?user_id=' + request).then(response => {
    commit("SET_GAME_DETAILS", response.data);
    EventBus.$emit("loader", false);
  })
}

// RESET USER PASSWORD 

export const RESETPASSWORD = ({ commit }, request) => {
  EventBus.$emit("loader", true);
  return api.put('users/resetpassword', request).then(response => {
    EventBus.$emit("loader", false);
    return response;
  })
}

//DOWNLOAD USER LIST 

export const DOWNLOADUSERLIST = ({ commit },params) => {
  EventBus.$emit("loader", true);
  return api.get("download/users-list",params).then(response => {
    // console.log('response------asdasdasdasd',response)
    EventBus.$emit("loader", false);
    return response;
  });
};

//DISABLEPOPUP 

export const DISABLEPOPUP = ({ commit },params) => {
  EventBus.$emit("loader", true);
  return api.post("rating-popup",params).then(response => {
    // console.log('response------asdasdasdasd',response)
    EventBus.$emit("loader", false);
    return response;
  });
};

export const DISABLEALLPOPUP = ({ commit },params) => {
  EventBus.$emit("loader", true);
  return api.post("rating-popup1",params).then(response => {
    // console.log('response------asdasdasdasd',response)
    EventBus.$emit("loader", false);
    return response;
  });
};
