import SimpleVueValidation from 'simple-vue-validator';
import { mapGetters, mapActions } from "vuex";
import AddAndEditModal from '@/components/Users/AddAndEditModal'
import FilterModal from '@/components/FilterModal'
import DeleteModal from "@/components/DeleteModal";


const Validator = SimpleVueValidation.Validator.create({
  templates: {
    Empty: 'This Field is required.'
  }
});;

export default {
  name: 'FreeUsers',
  components: {
    AddAndEditModal,
    FilterModal,
    DeleteModal
  },
  props: [],
  data() {
    return {
      allSelected: false,
      checkAllStatus: false,
      showAppTable : true,
      enable_status : false,
      json_data: [],
      column_sort : "asc",
      sort_column_name :'',
      sort_column_type :'',
      freeuserlist : [],
      columns: [
        // {
        //   label: 'Sl.No',
        //   field: 'sNo',
        //   filterable: false,
        // },
        {
          label: 'user_id',
          field: 'user_id',
          filterable: false,
        },
        {
          label: 'first_name',
          field: 'first_name',
          filterable: false,
        },
        {
          label: 'last_name',
          field: 'last_name',
          filterable: false,
        },
        {
          label: 'email',
          field: 'email',
          filterable: false,
        },
        {
          label: 'apple_email_id',
          field: 'apple_email_id',
          filterable: false,
        },
        {
          label: 'delete',
          field: 'delete',
          filterable: false,
        },
      ],
      rows1: [],
      tableResultRows: [],
      rows: null,
      perPage: null,
      currentPage: 1,
      users: [],
      timeVariable: null,
      sendMailModalId: "SendMailModal",
      activeTab: 'app',
      startDate: "",
      endDate: "",
      selectedRow: null,
      getResult: {},
      internaluserSearch: '',
      view: 2,
      totalCount: '',
      editId : '',
      deleteId : '',
      loadMoreStatus: false,
      free_user_email_id : '',
      edit_email_id : '',
      getMailIds: '',
      deleteModalId: "UserDetailDeleteModal",
    }
  },
  validators: {
    free_user_email_id: function(value) {
      return Validator.value(value)
        .required("This Field is mandatory")
        .email("Enter the Valid Email ID");
    },
    edit_email_id: function(value) {
      return Validator.value(value)
        .required("This Field is mandatory")
        .email("Enter the Valid Email ID");
    }
  },
  computed: {
  },
  watch: {
    activeTab: function () {
    }
  },
  mounted() {
    this.getInternalUserList();
  },
  watch: {
    internaluserSearch: function () {
      clearTimeout(this.timeVariable);
      let that = this;
      this.timeVariable = setTimeout(function () {
        that.searchData();
      }, 1000);
    }
  },
  methods: {
    ...mapActions("InternalUsers", ["GETUSERS",'ADDUSER', "EDITUSER","DELETEUSER","DOWNLOADINTERNALUSERLIST"]),

    //Toggle Modal
    deleteModal(userid) {
      this.deleteId = userid
      this.$refs['delete-modal1'].show()
    },
    cancelModal(data){
      this.$refs[data].hide()
    },  
    deleteUser(){
        this.$refs['delete-modal1'].hide()
        let params = {
          id : [this.deleteId]
        }
        this.DELETEUSER(params).then((response) => {
          if (response.status == 200) {
            this.$notify({
              type: "success",
              title: "Successfull",
              text: response.message
            });
            location.reload();
          }else {
            this.$notify({
              type: "warn",
              title: "Error",
              text: response.message
            });
          }
        });
    },

    downloadUserList(){
      this.DOWNLOADINTERNALUSERLIST().then((response) => {
      // console.log('response------11111111111111111',response)
        if (response.status === 200) {
          // window.open(
          //   response.url,
          //   "_blank"
          // );
          this.json_data = response.data.data
          // console.log('response.data-------',this.json_data)
          setTimeout(() => {
            this.$refs.fileInput.$el.click();
          }, 800);
        } else {
        }
      })
    },

    // SEARCH

    searchData() {
      let params = {
        search: this.internaluserSearch,
        column_name : this.sort_column_name,
        order_by : this.sort_column_type
      }
      this.GETUSERS(params).then((response) => {
        if (response.status === 200) {
          let responseData = response.data.data;
            this.rows1 = responseData.data;
            // console.log('response------',this.rows1)
            this.totalCount = response.data.data.total;
            this.currentPage = response.data.data.current_page;
            this.perPage = response.data.data.per_page;
            // console.log('response------',this.totalCount)
            // console.log('response------',this.currentPage)
            // console.log('response------',this.perPage)
        } 
      })
    },
    sorting(column_name){
      // console.log('dataaaaaaaaaa------------->>>>>>>>>',column_name);
      if(this.column_sort == 'asc'){
          this.sort_column_type = 'asc'
          this.sort_column_name = column_name;
          let params = {
            search : this.internaluserSearch,
            column_name : this.sort_column_name,
            order_by : this.sort_column_type
          }
            this.getInternalUserList(params);
            this.column_sort = 'desc'    
      }else{
        this.sort_column_type = 'desc'
          this.sort_column_name = column_name;
          let params = {
            search : this.internaluserSearch,
            column_name : this.sort_column_name,
            order_by : this.sort_column_type
          }
            this.getInternalUserList(params);
            this.column_sort = 'asc'
      }
    },

    // User List
     
    getInternalUserList(params) {
      // console.log('params===============<>>>>>>>>>>',params);
      if(params){
        this.GETUSERS(params).then((response) => {
          // console.log('response---------->>>>>11111111',response);
          if (response.status === 200) {
            let responseData = response.data.data;
            this.rows1 = responseData.data;
            // console.log('response------',this.rows1)
            this.totalCount = response.data.data.total;
            this.currentPage = response.data.data.current_page;
            this.perPage = response.data.data.per_page;
            // console.log('response------',this.totalCount)
            // console.log('response------',this.currentPage)
            // console.log('response------',this.perPage)
          }
        })
      }else{
        this.GETUSERS().then((response) => {
          // console.log('response---------->>>>>11111111',response);
          if (response.status === 200) {
            let responseData = response.data.data;
            this.rows1 = responseData.data;
            // console.log('response------',this.rows1)
            this.totalCount = response.data.data.total;
            this.currentPage = response.data.data.current_page;
            this.perPage = response.data.data.per_page;
            // console.log('response------',this.totalCount)
            // console.log('response------',this.currentPage)
            // console.log('response------',this.perPage)
          }
        })
      }
    },

    // Toggle SelectAll

    toggleSelectAll(event) {
      this.checkAllStatus = event.target.checked
      this.allSelected = !this.allSelected;

      this.tableResultRows.forEach(row => {
        if (this.allSelected) {
          // this.allSelected = true
          row.selected = true;
          this.checkeddData.checkedIds.push(row.id)
        } else {
          // this.allSelected = true            
          row.selected = false;
          this.checkeddData.checkedIds = []
        }
      })
    },

    //HANDLE PAGINATION

    handlePagination(val) {
      let params = {
        page: val,
        search : this.internaluserSearch,
        column_name : this.sort_column_name,
        order_by : this.sort_column_type
      }
      window.scrollTo({ top: 0, behavior: 'smooth' })
      this.getInternalUserList(params)
    },

    //HANDLE FILTER TABS

    handleFilter(value = 'app', event) {
      this.allSelected = false
      document.getElementById("checkBoxInput").checked = false;
      this.checkeddData.checkedIds = [],
        this.checkeddData.uncheckedIds = []
      this.activeTab = value
      this.startDate = ''
      this.checkAllStatus = false
      this.endDate = ''
      this.internaluserSearch = ''
      let params = {
        type: value,
        page: 1,
      }
      this.getInternalUserList(params)
    },
  }
}
