import { render, staticRenderFns } from "./DashboardTotalDownloadsGraph.html?vue&type=template&id=7a1fe8e2&scoped=true&"
import script from "./DashboardTotalDownloadsGraph.js?vue&type=script&lang=js&"
export * from "./DashboardTotalDownloadsGraph.js?vue&type=script&lang=js&"
import style0 from "./DashboardTotalDownloadsGraph.scss?vue&type=style&index=0&id=7a1fe8e2&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a1fe8e2",
  null
  
)

export default component.exports