import api from "@/store/services/api";
import { EventBus } from "@/services/eventBus.js"

export const LOGIN_DETAILS = ({
  commit
}, request) => {
  EventBus.$emit("loader", true);
  return api.post('admin/login', request).then(({ status, data }) => {
    if (status == 200) {
      localStorage.setItem("Token", `${data.data.token}`);
      commit("SET_LOGIN_DETAILS", data)
      EventBus.$emit("loader", false);
      return data;
    }
    else {
      commit("SET_LOGIN_DETAILS", '')
      EventBus.$emit("loader", false);
    }
  })
}

