import AppWidget from '@/components/AppWidget'
import UserStatistics from '@/components/Dashboard/UserStatistics'
import UserUsages from '@/components/Dashboard/UserUsages'
import SubscriptionGraph from '@/components/Dashboard/SubscriptionGraph'
import TotalDownloads from '@/components/Dashboard/TotalDownloads'
import DashboardTotalDownloadsGraph from '@/components/Dashboard/DashboardTotalDownloadsGraph'
import DashboardUsersGraph from '@/components/Dashboard/DashboardUsersGraph'
import DashboardGamesGraph from '@/components/Dashboard/DashboardGamesGraph'
import DashboardRoundsGraph from '@/components/Dashboard/DashboardRoundsGraph'
import { mapGetters, mapActions } from "vuex";
// import { EventBus } from "@/services/eventBus.js"


export default {
  name: 'dashboard',

  components: {
    AppWidget,
    UserStatistics,
    UserUsages,
    SubscriptionGraph,
    TotalDownloads,
    DashboardTotalDownloadsGraph,
    DashboardUsersGraph,
    DashboardGamesGraph,
    DashboardRoundsGraph
  },

  data() {
    return {
      rounds: [],
      users: [],
      filter:'Week',
      activeRoundsTab: 'all',
      activeUsersTab: 'all',
      currentPage: 1,
      userCounts:{},
      downloadGraph:null,
      userGraph:null,
      gamesGraph : null,
      roundsGraph : null,
      conversionFromTrail : null,
      selectedFilterDownloadsGraph : 'Week',
      selectedFilterUserGraph : 'Week',
      selectedFilterGamesGraph : 'Week',
      selectedFilterRoundsGraph : 'Week'
      
    }
  },
  async created() {
    // this.getRoundList();
    // this.getUserList();
   
   
    // await this.getTotalDownloadsGraphDetails();
    // await this.getRoundsGraphDetails();
    var that = this;
    setTimeout(async function() {
      setTimeout(async function() {
        setTimeout(async function() {
          setTimeout(async function() {
            await that.getRoundsGraphDetails();
          },800)
          await that.getUserGraphDetails();
        },800)
        await that.getTotalDownloadsGraphDetails();
      },800)
      await that.getGamesGraphDetails();
    },800)
    this.countList();
  },
  mounted(){
  },
  methods: {
    // ...mapActions("Rounds", ["GETROUNDS"]),
    // ...mapActions("Users", ["GETUSERS"]),
    ...mapActions("Dashboard", ["GETDETAILS","GETDOWNLOADGRAPHDETAILS","GETUSERGRAPHDETAILS","GETGAMESGRAPHDETAILS","GETROUNDSGRAPHDETAILS"]),

    //LIST

    // getRoundList(params) {
    //   this.GETROUNDS(params).then((response) => {
    //     this.rounds = response.data.data
    //   })
    // },

    //LIST

    // getUserList(params) {
    //   this.GETUSERS(params).then((response) => {
    //     this.users = response.data.data
    //   })
    // },

    filterChange(filter){
      // console.log('filter----',filter);
      this.filter = filter;

      filter.graphName === "TotalDownloads"
        ? this.getTotalDownloadsGraphDetails(filter.filter)
        : "";
      filter.graphName === "usersGraph"
        ? this.getUserGraphDetails(filter.filter)
        : "";
      filter.graphName === "gamesGraph"
        ? this.getGamesGraphDetails(filter.filter)
        : "";
      filter.graphName === "roundsGraph"
        ? this.getRoundsGraphDetails(filter.filter)
        : "";  
    },

    async getUserGraphDetails(params){
      // console.log('params------>>>.',params)
      this.userGraph = null
      this.selectedFilterUserGraph = params
      const response = await this.GETUSERGRAPHDETAILS(params)
      // console.log('GETUSERGRAPHDETAILS----->>>>>',response);
      this.userGraph = response.body.data
    },

    async getTotalDownloadsGraphDetails(params){
      // console.log('params------>>>1111.',params)
      this.downloadGraph = null
      this.selectedFilterDownloadsGraph = params;
     const response = await this.GETDOWNLOADGRAPHDETAILS(params)
        // console.log('GETDOWNLOADGRAPHDETAILS----->>>>',response)
        this.downloadGraph = response.body.data
    },

    async getGamesGraphDetails(params){
      // console.log('params------>>>1111.',params)
      this.gamesGraph = null
      this.selectedFilterGamesGraph = params;
      const response = await this.GETGAMESGRAPHDETAILS(params)
        // console.log('GETDOWNLOADGRAPHDETAILS----->>>>',response)
        this.gamesGraph = response.body.data
    },

    async getRoundsGraphDetails(params){
      // console.log('params------>>>1111.',params)
      this.roundsGraph = null
      this.selectedFilterRoundsGraph = params;
      const response = await this.GETROUNDSGRAPHDETAILS(params)
        // console.log('GETDOWNLOADGRAPHDETAILS----->>>>',response)
        this.roundsGraph = response.body.data
    },

    countList() {
      this.GETDETAILS().then((response) => {
        this.userCounts = response.data
      })
    },
  }
}
