import api from "@/store/services/api";
import { EventBus } from "@/services/eventBus.js"


// UPDATE CMS_DETAILS

export const CMS_DETAILS = ({
  commit
}, request) => {
  EventBus.$emit("loader", true);
  return api.put('cms/site-content', request).then(response => {
    commit("SET_CMS_DETAILS", response)
    EventBus.$emit("loader", false);
  })
}

// GET CMS_DETAILS

export const GET_CMS_DETAILS = ({
  commit
}, request) => {
  EventBus.$emit("loader", true);
  return api.get('cms/site-content', request).then(response => {
    commit("GET_CMS_DETAILS", response)
    EventBus.$emit("loader", false);
  })
}