import BeezerBankTable from '../../components/BeezerBank/BeezerBankTable';
import NoOfBetsGra from '../../components/NoOfBetsGra';
import TotBetAmtGra from '../../components/TotalBetAmtGra';
import BetBankTransGra from '../../components/BetBankTransGra';
import TotAmtBetBankTrnsGra from '../../components/TotAmtBetBankTrnsGra';
import BeezBalGra from '../../components/BeezBalGra';
import { mapGetters, mapActions } from "vuex";
import SimpleVueValidation from 'simple-vue-validator';
import FilterModal from '@/components/FilterModal'
const Validator = SimpleVueValidation.Validator.create({
  templates: {
    Empty: 'This Field is required.'
  }
});
export default {
  name: 'beezerBank',
  components: {
    BeezerBankTable,
    NoOfBetsGra,
    TotBetAmtGra,
    BetBankTransGra,
    TotAmtBetBankTrnsGra,
    FilterModal,
    BeezBalGra
  },
  props: [],
  data() {
    return {
      show_internal_user: 1,
      __status: 1,
      beezerBankSearch: '',
      text: '',
      rows: null,
      perPage: null,
      currentPage: 1,
      BeezerBankData: [],
      json_data: [],
      sort_column_name :'',
      sort_column_type :'',
      activeTab: 'All',
      startDate: "",
      endDate: "",
      FilterModalId: 'FilterModal',
      view: 1,
      selectedComponent: "NoOfBetsGra"
    }
  },
  validators: {
    startDate: function (value) {
      return Validator.value(value).required()
    },
    endDate: function (value) {
      return Validator.value(value).required()
    }
  },
  computed: {
    ...mapGetters("BeezerBank", ["getBeezerBankData"])
  },
  watch:{
    beezerBankSearch: function () {
      clearTimeout(this.timeVariable);
      let that = this;
      this.timeVariable = setTimeout(function () {
        that.searchData();
      }, 1000);
    }
  },
  created() {
  },
  mounted() {
    let params = {
      internal_user : this.show_internal_user,
    }
    this.getBeezerBankList(params);
  },
  methods: {
    ...mapActions("BeezerBank", ["GETBEEZE","DOWNLOADTRANSACTIONLIST"]),
    changeTab(name) {
      this.selectedComponent = name
    },

    //Search Data

    searchData(){
      var params = {
        internal_user : this.show_internal_user,
        filter:this.activeTab,
        search: this.beezerBankSearch,
        page: 1,
        ...(
          this.activeTab
            ? { filter : this.activeTab }
            : {
              start_date: this.startDate,
              end_date: this.endDate
            }),
      }
      this.GETBEEZE(params).then((response) => {
        let responseData = response.data.meta.pagination
        this.BeezerBankData = this.getBeezerBankData
        this.rows = responseData.total
        this.perPage = responseData.per_Page
        this.currentPage = responseData.current_page
      })
    },

    showInternalUser(data){
      // console.log('data----->>>>>>>',data.value);
      if(data.value == true){
        this.show_internal_user = 0;
        let params = {
          filter : this.activeTab,
          search: this.beezerBankSearch,
          internal_user : this.show_internal_user,
        }
        this.getBeezerBankList(params);
      }else{
        this.show_internal_user = 1;
        let params = {
          filter : this.activeTab,
          search: this.beezerBankSearch,
          internal_user : this.show_internal_user,
        }
        this.getBeezerBankList(params);
      }
    },

    // GET BEEZER BANK LIST

    getBeezerBankList(value) {
      if(!value){
        var params = {
          internal_user : this.show_internal_user,
          filter:value,
          search: this.beezerBankSearch,
          page: this.currentPage,
          ...(
            this.activeTab
              ? { filter : this.activeTab }
              : {
                start_date: this.startDate,
                end_date: this.endDate
              }),
          column_name : this.sort_column_name,
          order_by : this.sort_column_type 
        }
      }else{
          var params = {
            internal_user : this.show_internal_user,
            filter:value,
            search: this.beezerBankSearch,
            page: 1,
            ...(
              this.activeTab
                ? { filter : this.activeTab }
                : {
                  start_date: this.startDate,
                  end_date: this.endDate
                }),
            column_name : this.sort_column_name,
            order_by : this.sort_column_type 
          }
      }
      this.GETBEEZE(params).then((response) => {
        let responseData = response.data.meta.pagination
        this.BeezerBankData = this.getBeezerBankData
        this.rows = responseData.total
        this.perPage = responseData.per_Page
        this.currentPage = responseData.current_page
      })
    },

        //SORTING

    beezerbankSorting(data){
      // console.log('data0-----',data);
      this.sort_column_type = data.sortDesc == false ? 'asc' : 'desc'
      if(data.sortBy == "from") {
        this.sort_column_name = 'from_name'
      }
      if(data.sortBy == "to") {
        this.sort_column_name = 'to_name'
      }
      if(data.sortBy == "amount") {
        this.sort_column_name = 'amount'
      }
      if(data.sortBy == "date") {
        this.sort_column_name = 'round_player_wallet_id'
      }
      this.getBeezerBankList()
    },

      //DOWNLOAD TRANSACTION LIST

      downloadTransactionList(){
        let params ={
          internal_user : this.show_internal_user,
        }
        this.DOWNLOADTRANSACTIONLIST(params).then((response) => {
        // console.log('response------11111111111111111',response)
          if (response.status === 200) {
            // window.open(
            //   response.url,
            //   "_blank"
            // );
            this.json_data = response.data.data
            // console.log('response.data-------',this.json_data)
            setTimeout(() => {
              this.$refs.fileInput.$el.click();
            }, 800);
          } else {
          }
        })
      },

    //HANDLE PAGINATION

    handlePagination(val) {
      this.currentPage = val
      window.scrollTo({ top: 0, behavior: 'smooth' })
      this.getBeezerBankList()
    },

    //HANDLE FILTER TABS

    handleFilter(value = 'all') {
      this.activeTab = value
      this.startDate = ''
      this.endDate = ''
      let params = value
      this.getBeezerBankList(params)
    },

    //FILTER BY DATE RANGE

    filterByDateRange(data) {
      this.startDate = data.startDate
      this.endDate = data.endDate
      let params = {
        internal_user : this.show_internal_user,
        page: this.currentPage,
        start_date: this.startDate,
        end_date: this.endDate
      }
      this.getBeezerBankList(params);
      this.activeTab = ''
    }
  }
}
