export default {
  name: 'app-side-nav',
  components: {},
  props: [],
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0);
 }
  }
}
