<template>
  <Modal :id="id" :title="title" @resetState="resetState">
    <template #modal-card-body>
      <form @submit.prevent="onSubmit" class="p-3">
        <b-form class="custom-form">
          <b-form-group :class="{error: validation.hasError('firstName')}">
            <b-form-input type="text" v-model="firstName" required placeholder="Name"></b-form-input>
            <span class="text-danger small">{{ validation.firstError('firstName') }}</span>
          </b-form-group>

          <b-form-group :class="{error: validation.hasError('lastName')}">
            <b-form-input type="text" v-model="lastName" required placeholder="Last Name"></b-form-input>
            <span class="text-danger small">{{ validation.firstError('lastName') }}</span>
          </b-form-group>

          <b-form-group :class="{error: validation.hasError('nickName')}">
            <b-form-input
              type="text"
              v-model="nickName"
              onkeypress="return /[a-z]/i.test(event.key)"
              maxlength="3"
              required
              placeholder="Nick Name"
            ></b-form-input>
            <span class="text-danger small">{{ validation.firstError('nickName') }}</span>
          </b-form-group>

          <b-form-group :class="{error: validation.hasError('handicapIndex')}">
            <b-form-input
              type="text"
              v-model="handicapIndex"
              equired
              placeholder="Handicap Index"
              maxlength="2"
              onkeypress="return /[0-9 , +]/i.test(event.key)"
            ></b-form-input>
            <span class="text-danger small">{{ validation.firstError('handicapIndex') }}</span>
          </b-form-group>

          <b-form-group :class="{error: validation.hasError('email')}">
            <b-form-input type="email" v-model="email" required placeholder="Email"></b-form-input>
            <span class="text-danger small">{{ validation.firstError('email') }}</span>
          </b-form-group>
        </b-form>
      </form>
    </template>

    <template #modal-card-footer>
      <div class="text-center w-100">
        <b-button v-if="isVerified == '0'" size="sm" @click="isVerifyEmail(userid)" pill variant="outline-success" class="px-4 py-2 mx-2">Verify Email</b-button>
        <b-button size="sm" @click="onSubmit" pill variant="outline-success" class="px-4 py-2">Done</b-button>
      </div>
    </template>
  </Modal>
</template>
<script>
import Modal from "@/components/Modal";
import Vue from "vue";
import SimpleVueValidation from "simple-vue-validator";

const Validator = SimpleVueValidation.Validator.create({
  templates: {
    Empty: "This Field is required."
  }
});

function initialState() {
  return {
    firstName: "",
    lastName: "",
    nickName: "",
    handicapIndex: "",
    email: "",
    title: "Edit User",
    userid : '',
    isVerified : '',
  };
}

export default {
  name: "AddAndEditModal",
  props: {
    id: {
      type: String
    },
    selectedRow: {}
  },
  components: {
    Modal
  },
  data() {
    return initialState();
  },
  validators: {
    firstName: function(value) {
      return Validator.value(value).required("This Field is mandatory");
    },
    lastName: function(value) {
      return Validator.value(value).required("This Field is mandatory");
    },
    nickName: function(value) {
        return Validator.value(value).required('This Field is mandatory').length(3);
    },
    // handicapIndex: function(value) {
    //   return Validator.value(value).required("This Field is mandatory");
    // },
    email: function(value) {
      return Validator.value(value)
        .required("This Field is mandatory")
        .email("Enter the Valid Email ID");
    }
  },
  watch: {
    selectedRow: function(val) {
      let newData = Object.assign({}, val);
      if (newData) {
        // console.log('date---------',newData)
        this.firstName = newData.first_name;
        this.lastName = newData.last_name;
        this.handicapIndex = newData.handicap_index;
        this.nickName = newData.nick_name;
        this.email = newData.email;
        this.isVerified = newData.is_verified
        this.userid = newData.user_id
      }
    }
  },
  methods: {
    async onSubmit() {
      await this.$validate().then(success => {
        let {
          firstName,
          lastName,
          nickName,
          email,
          password,
          handicapIndex,
          selectedRow
        } = this;
        if (success) {
          let payload = {
            user_id: this.$route.query.id,
            first_name: firstName,
            last_name: lastName,
            nick_name: nickName,
            email: email,
            handicap_index: handicapIndex
          };
          if (!selectedRow) this.$emit("addUser", payload);
          else this.$emit("editUser", payload);
        }
      });
    },
    async isVerifyEmail(id){
      // console.log("idddd----",id)
      await this.$validate().then(success => {
        let {
          firstName,
          lastName,
          nickName,
          email,
          password,
          handicapIndex,
          selectedRow
        } = this;
        if (success) {
          let payload = {
            user_id: this.$route.query.id,
            first_name: firstName,
            last_name: lastName,
            nick_name: nickName,
            email: email,
            handicap_index: handicapIndex
          };
          // console.log('success----',payload)
          this.$emit("verifyEmail", payload);
        }
      });

    },
    resetState() {
      this.validation.reset();
    }
  }
};
</script>