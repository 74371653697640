import { render, staticRenderFns } from "./DashboardGamesGraph.html?vue&type=template&id=1ed19dd4&scoped=true&"
import script from "./DashboardGamesGraph.js?vue&type=script&lang=js&"
export * from "./DashboardGamesGraph.js?vue&type=script&lang=js&"
import style0 from "./DashboardGamesGraph.scss?vue&type=style&index=0&id=1ed19dd4&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ed19dd4",
  null
  
)

export default component.exports