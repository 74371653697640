export default {
  name: 'RoundsTable',
  components: {},
  props: [
    'items',
    'striped',
    'rowClick',
    'totalRows',
    'pageCount'
  ],
  data() {
    return {
      fields: [
        {
          key: 'S.No',
          label: 'S.No',
          sortable: false
        },
        {
          key: 'course_name',
          sortable: true
        },
        {
          key: 'rounds',
          sortable: true
        },
        {
          key: 'address',
          sortable: true
        },
        {
          key: 'city',
          sortable: true
        },
        {
          key: 'country',
          sortable: true
        },
        {
          key: 'gps',
          label: 'GPS',
          sortable: true,
        },
        {
          key: 'email',
          label: 'Email',
          sortable: false,
        },
        {
          key: 'apple_email_id',
          label: 'Apple Email Id',
          sortable: false,
        },
        {
          key: 'apple_id',
          label: 'Apple Id',
          sortable: false,
        }
      ],
    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {
    courseSort(data){
      // console.log('data------->>>>>',data);
      this.$emit('sortingdata',data)
    }
  }
}
