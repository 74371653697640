import { render, staticRenderFns } from "./AppPopover.html?vue&type=template&id=0561e23b&scoped=true&"
import script from "./AppPopover.js?vue&type=script&lang=js&"
export * from "./AppPopover.js?vue&type=script&lang=js&"
import style0 from "./AppPopover.scss?vue&type=style&index=0&id=0561e23b&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0561e23b",
  null
  
)

export default component.exports