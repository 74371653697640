import api from "@/store/services/api";
import { EventBus } from "@/services/eventBus.js";
import Vue from "vue";

export const GETUSERS = ({ commit }, params) => {
  EventBus.$emit("loader", true);
  if (typeof params == "object") {
    return api.get("free-user",params).then(response => {
      // console.log('response1111111111111------->>>>>>>',response)
      // commit("SET_SUBSCRIBERS_LIST", response.data);
      EventBus.$emit("loader", false);
      return response;
    });
  } else {
    return api.get("free-user").then(response => {
      // console.log('respons22222222------->>>>>>>',response)
      // commit("SET_SUBSCRIBERS_LIST", response.data);
      EventBus.$emit("loader", false);
      return response;
    });
  }
};

export const ADDUSER = ({ commit }, params) => {
  EventBus.$emit("loader", true);
  return api.post("free-user/add", params).then(response => {
    EventBus.$emit("loader", false);
    return response;
  });
};

export const EDITUSER = ({ commit }, params) => {
  EventBus.$emit("loader", true);
  return api.post("free-user/edit", params).then(response => {
    EventBus.$emit("loader", false);
    return response;
  });
};

export const DELETEUSER = ({ commit }, params) => {
  // console.log('params-----',params);
  EventBus.$emit("loader", true);
  return api.post("free-user/delete",params).then(response => {
    EventBus.$emit("loader", false);
    return response;
  });
};

export const SUBSCRIBERSEARCH = ({ commit }, params) => {
  EventBus.$emit("loader", true);
  return api.put("users", params).then(response => {
    if (reloadUserList) GETUSERS({ commit, dispatch });
    else GETUSERDETAILS({ commit, dispatch });
    EventBus.$emit("loader", false);
    return response
  });
};

export const DOWNLOADFREEUSERLIST = ({ commit }) => {
  EventBus.$emit("loader", true);
  return api.get("download/free-users").then(response => {
    // console.log('response------asdasdasdasd',response)
    EventBus.$emit("loader", false);
    return response;
  });
};