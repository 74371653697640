import { mapGetters, mapActions } from "vuex";
import AppPopover from '../../components/AppPopover'
export default {
  name: 'app-userGamesPlayed',

  components: {
    AppPopover
  },

  props: [],

  data() {
    return {
      games_played: [],
      striped: 'striped',
      fields: [
        'game',
        'no_of_times_played',
        'players'
      ]
    }
  },
  created() {
    this.getUserGameData();
  },
  computed: {
    ...mapGetters("Users", ["getUserGames"])
  },

  mounted() {
  },

  methods: {
    ...mapActions("Users", ["GETUSERGAMEDETAILS"]),
    getUserGameData() {
      this.GETUSERGAMEDETAILS(this.$route.query.id).then(() => {
        this.games_played = this.getUserGames
      });
    }
  }
}
