import { mapGetters, mapActions } from "vuex";
export default {
  name: 'app-userBankBalance',

  components: {},

  props: [],

  data() {
    return {
      bet_bank_balances: []
    }
  },
  created() {
  },
  computed: {
    ...mapGetters("Users", ["getUserDetails", "getUserBalance"])
  },

  mounted() {

  },

  methods: {
  }
}
