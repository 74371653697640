import {Chart} from 'highcharts-vue'
var Highcharts = require('highcharts');

export default {
  name: 'user-usages',

  components: {
    highcharts: Chart 
  },

  props: ['userUsage'],

  data () {
    return {

      chartOptionsPie: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
            text: ''
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false
                },
                showInLegend: true
            }
        },
        series: [{
            name: 'Brands',
            colorByPoint: true,
            data: [{
                name: 'Chrome',
                y: 61.41,
                sliced: true,
                selected: true
            }, {
                name: 'Internet Explorer',
                y: 11.84
            }, {
                name: 'Firefox',
                y: 10.85
            }, {
                name: 'Edge',
                y: 4.67
            }, {
                name: 'Safari',
                y: 4.18
            }, {
                name: 'Other',
                y: 7.05
            }]
        }]
      },

      chartOptions: {
        chart: {
          type: 'pie',
        },
        legend: true,
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.y}</b><br>Percent: <b>{point.percentage:.1f}%</b>'
        },
        plotOptions: {
            pie: {
                innerSize: 100,
                depth: 45
            }
        },
        series: [{
            name: 'Users',
            data: [
                {name:'Paid Users', y:this.userUsage.paidUsers, color:'#326fff'},
                {name:'Trial Users', y:this.userUsage.trailUsers, color:'#322929'},
                {name:'Free Users', y:this.userUsage.freeUsers, color:'#977BE2'}
            ]
        }]
      }

    }
  },

  computed: {

  },

  mounted () {

  },

  methods: {

  }
}
