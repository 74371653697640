<template>
<div class="loaderWrapper" v-if="loadingStatus">
  
  <div class="loader"></div>

</div>
  
</template>

<script>

import { EventBus } from "@/services/eventBus";

export default {
  name: "Loader",

  data() {
    return {
      loadingStatus: false
    };
  },
  components: {
    //   Loading
  },

  mounted() {
    var self = this;
    EventBus.$on("loader", status => {
      
      self.loadingStatus = status;
    });
  }
};
</script>

<style>
</style>
