import AppLogo from '@/components/AppLogo'
export default {
  name: 'app-header',
  components: { AppLogo },
  props: [],
  data() {
    return {

    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {
    logOut() {
      localStorage.clear()
      this.$router.push({ name: 'Login' })
    },
    closeNavBar(){
    }
  }
}
