import api from "@/store/services/api";
import { EventBus } from "@/services/eventBus.js";
import Vue from "vue";

export const GETGAMES = ({ commit }, params) => {
  EventBus.$emit("loader", true);
  if (typeof params == "object") {
    return api.get("game-statistic",params).then(response => {
      // console.log('response1111111111111------->>>>>>>',response)
      // commit("SET_SUBSCRIBERS_LIST", response.data);
      EventBus.$emit("loader", false);
      return response;
    });
  } else {
    return api.get("game-statistic").then(response => {
      // console.log('respons22222222------->>>>>>>',response)
      // commit("SET_SUBSCRIBERS_LIST", response.data);
      EventBus.$emit("loader", false);
      return response;
    });
  }
};

export const DOWNLOADGAMESLIST = ({ commit },params) => {
  EventBus.$emit("loader", true);
  return api.get("download/game-statistics",params).then(response => {
    // console.log('response------asdasdasdasd',response)
    EventBus.$emit("loader", false);
    return response;
  });
};