export default {
  name: 'app-button',
  components: {},
  props: [
    'type',
    'text'
  ],

  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
