// import { mapGetters, mapActions } from "vuex";
// import AppPopover from '../../components/AppPopover'
import Highcharts from 'highcharts'

export default {
  name: 'app-userBbTransactionGra',

  components: {
    // AppPopover
  },

  props: [],

  data() {
    return {
      chart:null,
      activeTab: 'Week',
      filter_date: '',
      chartOptions:{
        series: [{
          name: 'Transactions',
          data: [],
        }
        // , {
        //   name: 'Debit',
        //   data: [20, 60, 250, 280, 140, 210, 200]
        // }
        ]
      },
      xAxis:[]
    }
  },
  watch:{
    activeTab(newVal, oldVal){
      this.xAxis = [];
      this.$emit("changeFilter",newVal);
    }
  },
  created() {
  },
  async beforeCreate(){
  },
  computed: {

  },

  mounted() {
    // this.graphData();
  },

  methods: {
    // }
    UserDetailsBbTransactionGraphChange(data){
      this.xAxis = data.xAxis;
      this.filter_date = data.filterDate
      this.chartOptions.series[0].data = [];
      data.yAxis.forEach((item)=>{
        this.chartOptions.series[0].data.push({y:item })
      });
       // = data.yAxis;
      if(Array.isArray(data.xAxis)) {
        this.graphData();
      }
    },


    handleFilter(value) {
      this.activeTab = value
    },

    graphData(){
      Highcharts.setOptions({
        chart: {
          type: 'column',
        },
        title: {
          text: 'Transactions'
        },
        legend: {
          layout: 'vertical',
          align: 'left',
          verticalAlign: 'top',
          x: 150,
          y: 100,
          floating: true,
          borderWidth: 1,
        //   backgroundColor:
        //       Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF'
        },
        xAxis: {
          categories: this.xAxis,
          title: {
            // text: 'Transactions'
            text: ''
          },
          labels:{
            enabled: true 
          },
          plotBands: [{ // visualize the weekend
            // from: 4.5,
            // to: 6.5,
            // color: 'rgba(68, 170, 213, .2)'
          }]
        },
        yAxis: {
          title: {
            text: 'Transactions'
            // text: 'Amount (in $)'
          },
        },
        tooltip: {
          shared: true,
          valueSuffix: '',
          valuePrefix: ''
          // valuePrefix: '$'
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          areaspline: {
            fillOpacity: 0.5
          }
        },

      })

    }
  }
}
