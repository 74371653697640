import { mapGetters, mapActions } from "vuex";
export default {
  name: 'app-userRounds',

  components: {},

  props: ["striped","items"],

  data() {
    return {
      currentPage:1
    }
  },

  computed: {
    ...mapGetters("Users", ["getUserRounds","getRoundsPaginationDetails"])
  },

  created() {
  },
  methods: {
    ...mapActions("Users",['GETUSERROUNDSLIST']),

    handlePagination(val) {
      let params = {
        page: val,
      }
      // var container = this.$el.querySelector("#userdetails-rounds");
      window.scrollTo({ top: 0, behavior: 'smooth' })
      // container.scrollTop = container.scrollHeight;
      // window.scrollTo(0, $('.table-responsive').offset().top - 80)
      this.getUserRoundsData(params)
    },
    getUserRoundsData(params){
      this.GETUSERROUNDSLIST({id: this.$route.query.id, page: params.page}).then((response) => {
        // this.currentPage = response.meta.pagination.current_page
      });
    },
  },
  mounted(){
  }
}
