import AppHeader from "@/components/AppHeader";
import AppSideNav from "@/components/AppSideNav"
import LoaderDiv from "@/components/loader";
export default {
    name: 'admin',

    components: {
        AppHeader,
        AppSideNav,
        LoaderDiv
    },

    data() {
        return {
            proceed:false,
            loading: true
        }
    },
    computed: {

    },
    created() {
        if (!localStorage.getItem('Token')) {
            this.$router.push('/login')
        }
        else{
            this.proceed = true;
        }
    }
}
