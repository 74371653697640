<template>
	<Modal :id="id" title="Filter" @resetState="resetState">
		<template #modal-card-body>
			<form @submit.prevent="onSubmit" class="p-3">
	            <div class="form-group" :class="{error: validation.hasError('startDate')}">
	              <datetime v-model="startDate" class="custom-vueDate ">
	                <label for="startDate" slot="before" class="text-secondary">Select Start Date: </label>
	              </datetime>
	              <span class="text-danger small">{{ validation.firstError('startDate') }}</span>
	            </div>
	            <div class="form-group" :class="{error: validation.hasError('endDate')}">
	              <datetime v-model="endDate" class="custom-vueDate">
	                <label for="endDate" slot="before" class="text-secondary">Select End Date: </label>
	              </datetime>
	              <span class="text-danger small">{{ validation.firstError('endDate') }}</span>
	            </div>
	          </form>
		</template>
		<template #modal-card-footer>
          <div class="text-center w-100 ">
            <b-button
              size="sm"
              @click="onSubmit"
              pill
              variant="outline-success"
              class="px-4 py-2"
            >Done</b-button>
          </div>
        </template>
	</Modal>
</template>

<script>
	import Modal from '../Modal'
	import moment from 'moment'
	import SimpleVueValidation from 'simple-vue-validator';
	const Validator = SimpleVueValidation.Validator.create({templates: {
	  Empty: 'This Field is required.'
	}});;
	const initialState = () => ({
			      startDate: "",
			      endDate: ""
			})
	export default {
		name:'FilterModal',
		props:{
			id:{
				type:String
			}
		},
		  validators: {
		    startDate:  function(value) {
		      return  Validator.value(value).required('This Field is required')
		    },
		    endDate:function(value) {
				return Validator.custom( () => {
					 if (Validator.isEmpty(value)) {
						 return 'This Field is required'
					 }else{
						 if(!moment(new Date(value)).isAfter(new Date(this.startDate)))
						 return "Please enter valid end date"
					 }
				})
		    }
		  },
		data(){
			return initialState()
		},
		components:{
			Modal
		},
		methods:{
			onSubmit(){
				this.$validate().then((isValid) => {
			        if(isValid){
			        	let payload = {
			        		startDate:this.startDate,
			        		endDate:this.endDate
			        	}
			          this.$emit('filterByRange',payload)
			        }
			       })
			},
			resetState(){
				Object.assign(this.$data, initialState());
				this.validation.reset()
			}

		}
	}
</script>