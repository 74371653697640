import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

let load = component => {
  return () => import(`@/${component}/index.vue`);
};

export default new Router({
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: load('views/Login'),
    },
    {
      path: '/',
      component: load('layouts/Admin'),
      redirect: 'dashboard',
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: load('views/Dashboard'),
        },
        {
          path: 'users',
          name: 'Users',
          component: load('views/Users'),
        },
        {
          path: 'users/details',
          name: 'UsersDetails',
          component: load('views/UsersDetails'),
        },
        {
          path: 'rounds',
          name: 'Rounds',
          component: load('views/Rounds'),
        },
        {
          path: 'courses',
          name: 'Courses',
          component: load('views/Courses'),
        },
        {
          path: 'games',
          name: 'Games',
          component: load('views/Games'),
        },
        {
          path: 'games/details',
          name: 'GamesDetails',
          component: load('views/GamesDetails'),
        },
        {
          path: 'games/detailsform',
          name: 'GamesDetailsForm',
          component: load('views/GamesDetailsForm'),
        },
        {
          path: 'bets-bank',
          name: 'BetsBank',
          component: load('views/BeezerBank'),
        },
        {
          path: 'cms',
          name: 'CMS',
          component: load('views/Cms'),
        },
        {
          path: 'free-users',
          name: 'FreeUsers',
          component: load('views/FreeUsers'),
        },
        {
          path: 'internal-users',
          name: 'InternalUsers',
          component: load('views/InternalUsers'),
        },
        {
          path: 'games',
          name: 'Games',
          component: load('views/Games'),
        },
        {
          path:'communications',
          name:'Communications',
          component: load('views/Communications')
        },
        {
          path:'downloads',
          name:'Downloads',
          component: load('views/Downloads')
        },
      ]
    }
  ]
})
