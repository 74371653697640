import DeleteModal from "@/components/DeleteModal";
import UsersTable from "@/components/Users/UsersTable";
import UsersGraph from "@/components/Users/UsersGraph";
import { mapGetters, mapActions } from "vuex";
import SimpleVueValidation from "simple-vue-validator";
import AddAndEditModal from "@/components/Users/AddAndEditModal";
import FilterModal from "@/components/FilterModal";
import { EventBus } from "@/services/eventBus.js"

const Validator = SimpleVueValidation.Validator.create({
  templates: {
    Empty: "This Field is required."
  }
});

export default {
  name: "users",
  components: {
    AddAndEditModal,
    DeleteModal,
    UsersTable,
    FilterModal,
    UsersGraph
  },
  props: [],
  data() {
    return {
      show_internal_user: 1,
      usersList : [],
      rows: null,
      perPage: null,
      // toggleData : '',
      currentPage: 1,
      addModalCheck: "",
      activeTab: "",
      startDate: "",
      json_data: [],
      endDate: "",
      userSearch:'',
      addModalId: "AddAndEditUserModal",
      FilterModalId: "FilterModal",
      deleteModalId: "DeleteUserModal",
      selectedRow: null,
      selectedId: {},
      select_all_toggle:false,
      sort_column_name:'',
      sort_column_type:'',
      disable_Popup_Id:'',
      disable_Popup_Status: 0, 
      fetchSelectedData:'',
      fields: [
        "first_name",
        "last_name",
        "nick_name",
        "email_id",
        "hcp",
        "platform",
        "location",
        "created_date",
        "status",
        "actions"
      ],
      view: 1
    };
  },
  validators: {
    startDate: function (value) {
      return Validator.value(value).required();
    },
    endDate: function (value) {
      return Validator.value(value).required();
    }
  },
  computed: {
    ...mapGetters("Users", ["getUsersData", "getFullUserData"])
  },
  // watch:{
  //   userSearch: function () {
  //     clearTimeout(this.timeVariable);
  //     let that = this;
  //     this.timeVariable = setTimeout(function () {
  //       that.searchData();
  //     }, 1000);
  //   }
  // },
  mounted() {
    this.rows = null
    this.show_internal_user = 1
      this.perPage =  null,
      // toggleData : '',
      this.currentPage =  1,
      this.addModalCheck =  "",
      this.activeTab =  "",
      this.startDate = "",
      this.json_data = [],
      this.endDate = "",
      this.userSearch =''
    // this.getUserList(params);
    // EventBus.$on('emittedEvent', data => {
    //   this.activeTab = "All"
    //   this.getUserList();
    // });
  },
  methods: {
    ...mapActions("Users", [
      "GETUSERS",
      "ADDNEWUSER",
      "DELETEUSER",
      "EDITUSER",
      "DOWNLOADUSERLIST",
      "DISABLEPOPUP",
      "DISABLEALLPOPUP"
    ]),
    toggleModal(modalId, data) {
      this.addModalCheck = data;
      if (modalId === "AddAndEditUserModal") {
        if (data == 'addModal') {
          this.selectedRow = null;
        }
      }
      this.$root.$emit("bv::toggle::modal", modalId);
    },

    fetchData(value){
      // console.log('value---->>>>>DDDd',value)
      this.activeTab = value
      let params = {
        search: this.userSearch,
        user_filter : value,
        internal_user : this.show_internal_user,
        page: 1,
      }
    this.getUserList(params);
  },

    // DELETE USER DATA

    deleteUser() {
      let params = {
        internal_user : this.show_internal_user,
        user_id: this.selectedRow.user_id
      };
      this.toggleModal(this.deleteModalId);
      this.DELETEUSER({ params }).then(response => {
        if (response.status == 200) {
          this.$notify({
            type: "success",
            title: "Successfull",
            text: response.message
          });
          this.fetchData(this.activeTab);
        } else {
          this.$notify({
            type: "warn",
            title: "Error",
            text: response.message
          });
        }
      });
    },

    showInternalUser(data){
      // console.log('data----->>>>>>>',data.value);
      if(data.value == true){
        this.show_internal_user = 0;
        this.fetchData(this.activeTab);
      }else{
        this.show_internal_user = 1;
        this.fetchData(this.activeTab);
      }
    },

    // HANDLE TABLE ROW CLICK

    handleCellClick({ index, operation }) {
      let id = operation === "delete" ? this.deleteModalId : this.addModalId;
      var users = this.getFullUserData;
      this.selectedRow = Object.assign({}, users[index]);
      this.toggleModal(id);
    },

    //LIST

    getUserList(params) {
      this.GETUSERS(params).then((response) => {
        let responseData = response.data.data.meta.pagination;
        this.rows = responseData.total;
        this.perPage = responseData.per_page;
        this.currentPage = responseData.current_page;
        this.usersList = this.getUsersData
        this.select_all_toggle = response.data.data.data.length > 0 ? response.data.data.data[0].select_all : false
        console.log('response.data.data[0].select_all-----',response.data.data.data)
      });
    },

    userSorting(data){
      // console.log('data0-----',data);
      this.sort_column_type = data.sortDesc == false ? 'asc' : 'desc'

      if(data.sortBy == "email_id") {
        this.sort_column_name = 'email'
      }else if(data.sortBy == 'created_date'){
        this.sort_column_name = 'created_at'
      }else if(data.sortBy == 'Verified'){
        this.sort_column_name = 'is_verified'
      }else if(data.sortBy == 'HCP'){
        this.sort_column_name = 'handicap_index'
      }else if(data.sortBy == 'apple_mail_id'){
        this.sort_column_name = 'apple_email_id'
      }else if(data.sortBy == 'disable_popup'){
        this.sort_column_name = 'is_rating_disabled'
      }else{
        this.sort_column_name = data.sortBy
      }
      let params = {
        search:this.userSearch,
        internal_user : this.show_internal_user,
        user_filter : this.activeTab,
        column_name : this.sort_column_name,
        order_by : this.sort_column_type
      }
      this.getUserList(params)
    },


    //DISABLE POPUP

    disablepopup(data){
      // console.log('date--------',data)
      if(data.value == true){
        this.disable_Popup_Status = 1
      }else{
        this.disable_Popup_Status = 0
      }
      let params ={
       user_id : data.tag ,
       status : this.disable_Popup_Status
      } 
      this.DISABLEPOPUP(params).then((response) => {
        // console.log(response)

        if(response.status = 200){
          let params = {
            search:this.userSearch,
            internal_user : this.show_internal_user,
            user_filter : this.activeTab,
            column_name : this.sort_column_name,
            order_by : this.sort_column_type
          }
          this.getUserList(params)
        }
        // let responseData = response.data.data.meta.pagination;
        // this.rows = responseData.total;
        // this.perPage = responseData.per_page;
        // this.currentPage = responseData.current_page;
      });
    },

    disableAllPopup(event){
      // console.log('date--------11111111',event.value)
      let params = {
        user_id : '',
        status : 1,
        select_all : event.value
      } 
      this.DISABLEPOPUP(params).then((response) => {
        // console.log(response)
        if(response.status = 200){
          let params1 = {
            search:this.userSearch,
            internal_user : this.show_internal_user,
            user_filter : this.activeTab,
            column_name : this.sort_column_name,
            order_by : this.sort_column_type
          }
          this.getUserList(params1)
        }
      });
    },

    //DOWNLOAD USER LIST

    downloadUserList(){
      let params = {
        internal_user : this.show_internal_user,
      }
      this.DOWNLOADUSERLIST(params).then((response) => {
      // console.log('response------11111111111111111',response)
        if (response.status === 200) {
          // window.open(
          //   response.url,
          //   "_blank"
          // );
          this.json_data = response.data.data
          // console.log('response.data-------',this.json_data)
          setTimeout(() => {
            this.$refs.fileInput.$el.click();
          }, 800);
        } else {
        }
      })
    },

    //SEARCH
    
    searchData(){
      var params = {
        internal_user : this.show_internal_user,
        user_filter:this.activeTab,
        search: this.userSearch,
        page: 1,
        ...(
          this.activeTab
            ? { filter : this.activeTab }
            : {
              start_date: this.startDate,
              end_date: this.endDate
            })
      }
      this.GETUSERS(params).then((response) => {
        let responseData = response.data.data.meta.pagination;
        this.rows = responseData.total;
        this.perPage = responseData.per_page;
        this.currentPage = responseData.current_page;
      });
    },


    //HANDLE PAGINATION

    handlePagination(val) {
      let params = {
        internal_user : this.show_internal_user,
        search:this.userSearch,
        page: val,
        ...(this.activeTab
          ? { user_filter: this.activeTab }
          : {
            start_date: this.startDate,
            end_date: this.endDate
          }),
        column_name : this.sort_column_name,
        order_by : this.sort_column_type
      };
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.getUserList(params);
    },

    //HANDLE FILTER TABS

    handleFilter(value = "all") {
      this.activeTab = value;
      this.startDate = "";
      this.endDate = "";
      this.currentPage = 1
      let params = {
        internal_user : this.show_internal_user,
        user_filter: value,
        search:this.userSearch,
        page: this.currentPage,
      }
      this.getUserList(params);
    },

    //FILTER BY DATE RANGE

    filterByDateRange(data) {
      this.startDate = data.startDate;
      this.endDate = data.endDate;

      let params = {
        internal_user : this.show_internal_user,
        page: this.currentPage,
        start_date: this.startDate,
        end_date: this.endDate
      };
      this.getUserList(params);
      this.toggleModal(this.FilterModalId);
      this.activeTab = "";
    },
  },
};
