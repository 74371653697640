const state = {
    list: [],
    fullList: [],
    rows: 4,
    perPage: 20,
    currentPage: 0,
    userRoundsDetails: [],
    getUserDetails: {},
    userTransactionDetails: [],
    userBalanceDetails: {},
    userGameDetails: [],
    resetResponseDetails:{},
    newUserAdded:false,
    roundsPaginationDetails:{},
    transactionPaginationDetails:{}
}

export default state