export const SET_ROUND_LIST = (state, payload) => {
    let response = payload.data
    let pagination = payload.meta.pagination;
    let slno = (payload.meta.pagination.current_page-1)*payload.meta.pagination.per_page;
    // console.log('response------',response)
    const list = response.map(data => ({
        // "S.No":++slno,
        // round_id: data.round_id,
        course_name: data.course_name ? data.course_name : '-',
        country: data.country ? data.country : '-',
        course_id: data.id_course ? data.id_course : '-',
        date: data.date ? data.date : '-',
        players: data.players.join(',') ? data.players.join(',') : '-',
        number_of_groups: data.number_of_groups,
        number_of_players: data.number_of_players ? data.number_of_players : '-',
        'Email id' : data.email.join(', ') ? data.email.filter(n => n).join(', ') : '-',
        apple_email_id : data.apple_email_id.join(', ') ? data.apple_email_id.join(', ') : '-',
        apple_id : data.apple_id.join(', ') ? data.apple_id.join(', ') : '-',
        games: data.game.join(', ') ? data.game.join(', ') : 'No game',
        // Tees: data.teeName ? data.teeName : '-',
        round_duration: data.round_total_time ,
        "Total Bets" : data.total_bets ,
        "Holes" : data.holes ? data.holes : '-',
        "Watch" : data.is_watch_scored ,
        // "Place" : data.holes ? data.holes : '-',
        // 'Total Bets' : data.total_bets ? data.total_bets : '-',
        status: data.is_end_round ? "Completed" : "Paused",
        invite: data.invite
    }))
    state.list = list;
};

  export const SETGAMELIST = (state, payload) => {
    let response = payload.data
    const gameList = response.map(data => ({
        name: data.name ? data.name : '-',
    }))
    state.gameList = gameList;
  }