import Vue from 'vue';
import Vuex from 'vuex';
import Login from './Login';
import Dashboard from "./Dashboard";
import Rounds from './Rounds';
import Course from './Course';
import BeezerBank from './BeezerBank';
import Users from './Users';
import Cms from './Cms';
import Communications from './Communications'
import FreeUsers from './FreeUsers'
import InternalUsers from './InternalUsers'
import Games from './Games'
import Downloads from './Downloads'



Vue.use(Vuex)
export default new Vuex.Store({
    modules: {
        Cms,
        Login,
        Dashboard,
        Rounds,
        Course,
        BeezerBank,
        Users,
        Communications,
        FreeUsers,
        InternalUsers,
        Games,
        Downloads
    }
})
