import Vue from "vue";
import "../../../config/VueResource";
import Router from '../../router'
import { EventBus } from "@/services/eventBus.js"

export default {
  get(url, request) {
    return Vue.http
      .get(url, { params: request })
      .then(response => Promise.resolve(response))
      .catch(handleUnAuthorized);
  },
  post(url, request) {
    return Vue.http
      .post(url, request)
      .then(response => Promise.resolve(response))
      .catch(handleUnAuthorized);
  },
  multipart(url, request) {
    let headers = {
      'Content-Type': 'multipart/form-data'
    };
    return Vue.http
      .post(url, request, {headers})
      .then(response => Promise.resolve(response))
      .catch(handleUnAuthorized);
  },
  put(url, request) {
    return Vue.http
      .put(url, request)
      .then(response => Promise.resolve(response))
      .catch(handleUnAuthorized);
  },
  patch(url, request) {
    return Vue.http
      .patch(url, request)
      .then(response => Promise.resolve(response))
      .catch(handleUnAuthorized);
  },
  delete(url, request) {
    return Vue.http
      .delete(url, { body: request })
      .then(response => Promise.resolve(response))
      .catch(handleUnAuthorized);
  }
};

const handleUnAuthorized = ({ body, status }) => {
  let { message } = body
  if (status === 401) {
    localStorage.clear();
    Router.go('/')
  }
  EventBus.$emit("loader", false);
  Vue.notify({
    type: status === 401 ? 'warn' : 'error',
    title: status === 401 ? 'Internal Server Error' : 'Error',
    text: message
  })
  return Promise.reject()
}
