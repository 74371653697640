import CoursesTable from '../../components/Courses/CoursesTable';
import { mapGetters, mapActions } from "vuex";
import SimpleVueValidation from 'simple-vue-validator';
import FilterModal from '@/components/FilterModal'
const Validator = SimpleVueValidation.Validator.create({
  templates: {
    Empty: 'This Field is required.'
  }
});;
export default {
  name: 'courses',
  components: {
    CoursesTable,
    FilterModal
  },
  props: [],
  data() {
    return {
      show_internal_user: 1,
      rows: null,
      perPage: null,
      currentPage: 1,
      activeTab: 'All',
      startDate: "",
      endDate: "",
      FilterModalId: 'FilterModal',
      courseSearch:'',
      json_data: [],
      sort_column_name :'',
      sort_column_type :'',
      fetchSelectedData : ''
    }
  },
  validators: {
    startDate: function (value) {
      return Validator.value(value).required()
    },
    endDate: function (value) {
      return Validator.value(value).required()
    }
  },
  computed: {
    ...mapGetters("Course", ["getCourseData"])
  },
  created() {
  },
  mounted() {
    // let params = {
    //   internal_user : this.show_internal_user
    // }
    // this.getCourseList(params);
  },
  watch:{
    // courseSearch: function () {
    //   clearTimeout(this.timeVariable);
    //   let that = this;
    //   this.timeVariable = setTimeout(function () {
    //     that.searchData();
    //   }, 1000);
    // }
  },
  methods: {
    ...mapActions("Course", ["GETCOURSES","DOWNLOADCOURSELIST"]),
    
    fetchData(value){
      // console.log('value---->>>>>DDDd',value)
      this.fetchSelectedData = value
      let params = {
        filter : this.activeTab,
        search: this.courseSearch,
        internal_user : this.show_internal_user,
        course_filter : this.fetchSelectedData
      }
      this.getCourseList(params);
    },


    // GET COURSES LIST

    getCourseList(params) {
      this.GETCOURSES(params).then((response) => {
        let responseData = response.data.meta.pagination
        this.rows = responseData.total
        this.perPage = responseData.per_page
        this.currentPage = responseData.current_page
        // console.log('this.perpage----->> >>>',this.perPage)
        // console.log('this.currentpage---->> >>>',this.currentPage)
      })
    },

    showInternalUser(data){
      // console.log('data----->>>>>>>',data.value);
      if(data.value == true){
        this.show_internal_user = 0;
        let params = {
          filter : this.activeTab,
          search: this.courseSearch,
          internal_user : this.show_internal_user,
          course_filter : this.fetchSelectedData
        }
        this.getCourseList(params);
      }else{
        this.show_internal_user = 1;
        let params = {
          filter : this.activeTab,
          search: this.courseSearch,
          internal_user : this.show_internal_user,
          course_filter : this.fetchSelectedData
        }
        this.getCourseList(params);
      }
    },

    //SEARCH

    searchData(){
      var params = {
        internal_user : this.show_internal_user,
        filter:this.activeTab,
        search: this.courseSearch,
        course_filter : this.fetchSelectedData,
        page: 1,
        ...(
          this.activeTab
            ? { filter : this.activeTab }
            : {
              start_date: this.startDate,
              end_date: this.endDate
            })
      }
      this.GETCOURSES(params).then((response) => {
        let responseData = response.data.meta.pagination
        this.rows = responseData.total
        this.perPage = responseData.per_page
        this.currentPage = responseData.current_page
        // console.log('this.perpage----->> >>>',this.perPage)
        // console.log('this.currentpage---->> >>>',this.currentPage)      
      })
    },

    //DOWNLOAD COURSE LIST

    downloadCourseList(){
      let params = {
        internal_user : this.show_internal_user,
      }
      this.DOWNLOADCOURSELIST(params).then((response) => {
      // console.log('response------11111111111111111',response)
        if (response.status === 200) {
          // window.open(
          //   response.url,
          //   "_blank"
          // );
          this.json_data = response.data.data
          // console.log('response.data-------',this.json_data)
          setTimeout(() => {
            this.$refs.fileInput.$el.click();
          }, 800);
        } else {
        }
      })
    },

    //SORTING

    courseSorting(data){
      // console.log('data0-----',data);
      this.sort_column_type = data.sortDesc == false ? 'asc' : 'desc'

      if(data.sortBy == "address") {
        this.sort_column_name = 'address1'
      }else if(data.sortBy == 'rounds'){
        this.sort_column_name = 'rounds_count'
      }else if(data.sortBy == 'gps'){
        this.sort_column_name = 'gpsAvailable'
      }else{
        this.sort_column_name = data.sortBy
      }
      let params = {
        internal_user : this.show_internal_user,
        filter : this.activeTab,
        column_name : this.sort_column_name,
        order_by : this.sort_column_type,
        search : this.courseSearch,
        course_filter : this.fetchSelectedData
      }
      this.getCourseList(params)
    },

    //HANDLE PAGINATION

    handlePagination(val) {
      let params = {
        internal_user : this.show_internal_user,
        search : this.courseSearch,
        page: val,
        ...(
          this.activeTab
            ? { filter: this.activeTab }
            : {
              start_date: this.startDate,
              end_date: this.endDate
            }),
        column_name : this.sort_column_name,
        order_by : this.sort_column_type,
        course_filter : this.fetchSelectedData
      }
      window.scrollTo({ top: 0, behavior: 'smooth' })
      this.getCourseList(params)
    },
    
    //HANDLE FILTER TABS

    handleFilter(value = 'all') {
      this.activeTab = value
      this.startDate = ''
      this.endDate = ''
      this.currentPage = 1
      let params = {
        internal_user : this.show_internal_user,
        search : this.courseSearch,
        page: this.currentPage,
        filter: value,
        course_filter : this.fetchSelectedData
      }
      this.getCourseList(params)
    },

    //FILTER BY DATE RANGE

    filterByDateRange(data) {

      this.startDate = data.startDate
      this.endDate = data.endDate

      let params = {
        internal_user : this.show_internal_user,
        page: this.currentPage,
        start_date: this.startDate,
        end_date: this.endDate,
        course_filter : this.fetchSelectedData
      }

      this.getCourseList(params);
      this.activeTab = ''
    }
  }
}
