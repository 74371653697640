export default {
  name: 'app-popover',
  components: {},
  props: ['players', 'players_list'],
  data() {
    return {
    }
  },
  computed: {

  },
  mounted() {
  },
  methods: {
    closePlayers() {
      this.$emit('closePopover')
    }
  }
}
