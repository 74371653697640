export default {
  name: 'app-toggle',
  components: {},
  props:['checkedData'],
  data () {
    return{
    }
  },
  computed: {

  },
  mounted () {
  },
  methods: {
  }
}
