import Highcharts from 'highcharts'
import { mapGetters, mapActions } from "vuex";

export default {
  name: 'BetBankTrans',
  props: [],
  data() {
    return {
      chart:null,
      activeTab: 'Week',
      chartOptions:{
        series: [{
          name: 'Transactions',
          data: []
        }]
      },
      xAxis:[]
    }
  },
  watch:{
    activeTab(newVal, oldVal){
      this.xAxis = [];
      this.getBetBankTransGraFun();
    },
  },
  mounted() {
    this.getBetBankTransGraFun();
    this.graphData();
  },

  methods: {
    ...mapActions("BeezerBank", ["GETBBTRANSGRA"]),

    // GET BET BANK TRANSACTION GRAPH DATA

    getBetBankTransGraFun() {
      this.GETBBTRANSGRA({filter:this.activeTab}).then((response) => {
        this.xAxis = response.data.data.date;
        this.chartOptions.series[0].data = response.data.data.count;
        this.graphData();
      });
    },

    // HANDLE FILTER

    handleFilter(value) {
      this.activeTab = value
    },

    // GRAPH DATA

    graphData(){
      Highcharts.setOptions({
        chart: {
          type: 'column'
        },
        title: {
          text: 'Transactions'
        },
        legend: {
          layout: 'vertical',
          align: 'left',
          verticalAlign: 'top',
          x: 150,
          y: 100,
          floating: true,
          borderWidth: 1,
          backgroundColor: '#FFFFFF'
        },
        xAxis: {
          categories: this.xAxis,
          plotBands: [{ // visualize the weekend
            // from: 4.5,
            // to: 6.5,
            // color: 'rgba(68, 170, 213, .2)'
          }]
        },
        yAxis: {
          title: {
            text: 'Transactions'
            // text: 'Amount (in $)'
          },
        },
        tooltip: {
          shared: true,
          valueSuffix: '',
          valuePrefix: ''
          // valuePrefix: '$'
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          areaspline: {
            fillOpacity: 0.5
          }
        },

      })

    }
  }
}
