import { render, staticRenderFns } from "./BetBankTransGra.html?vue&type=template&id=400b5763&scoped=true&"
import script from "./BetBankTransGra.js?vue&type=script&lang=js&"
export * from "./BetBankTransGra.js?vue&type=script&lang=js&"
import style0 from "./BetBankTransGra.scss?vue&type=style&index=0&id=400b5763&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "400b5763",
  null
  
)

export default component.exports