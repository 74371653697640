export default {
  name: 'BeezerBankTable',
  components: {},
  props: [
    'items',
    'striped',
    'rowClick',
    'totalRows',
    'pageCount'
  ],
  data() {
    return {
      fields: [
        {
          key: 'S.No',
          label: 'S.No',
          sortable: false
        },
        {
          key: 'email',
          sortable: false
        },
        {
          key: 'apple_email',
          sortable: false
        },
        {
          key: 'apple_id',
          sortable: false
        },
        {
          key: 'from',
          sortable: true
        },
        {
          key: 'to',
          sortable: true
        },
        {
          key: 'amount',
          sortable: true
        },
        {
          key: 'date',
          sortable: true
        },
      ],
    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {
    beezerbankSort(data){
      // console.log('data------->>>>>',data);
      this.$emit('beezerbanksortingdata',data)
    }
  }
}
