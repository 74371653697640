<template>
  <Modal :id="id" :title="title" :value="value" @resetState="resetState" class="px-4">
    <template #modal-card-body>
      <div class="text-center px-2">
        <h4 class="font-weight-bold">
          Do you wish to {{value ? 'enable' : 'disable'}} ratings pop up for all users?
        </h4>
      </div>
    </template>
    <template #modal-card-footer>
      <div class="text-center w-100 px-2">
        <b-button size="sm" pill variant="outline-success" class="px-4 py-2" @click="onSubmit">
            Yes
        </b-button>
        <b-button size="sm" pill variant="outline-success" @click="close()" class="px-4 py-2 ml-2" >
            No
        </b-button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "../Modal";
export default {
  name: "disbleRatingModal",
  props: {
    id: {
      default: "DisableRating",
      type:String
    },

    title:{
        default: "Disable Rating",
        type:String
    },

    value:{
        default: false,
        type:Boolean
    },

  },
  components: {
    Modal
  },
  methods: {
    onSubmit() {
      this.$emit("onSubmitDisableRating");
    },
    resetState() {
        this.$emit('reset')
    },
    close(){
      this.$root.$emit("bv::toggle::modal", 'DisableRatingModal')
    }
  }
};
</script>