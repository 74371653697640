export default {
  name: 'app-input',
  components: {},
  props: ['value'],
  data () {
    return{
      
    }
  },
  computed: {

  },
  mounted () {
    
  },
  methods: {

  }
}
