export default {
  name: 'UsersTable',
  props: [
    'items',
    'striped',
    'rowClick',
    'totalRows',
    'pageCount',
    'fields'
  ],
  data() {
    return {
      selectedRow: [],
      currentPage: 1,
      active: false,
      toggleData : '',
      syncEnable: false,
      columns: [
        {
          key: 'S.No',
          label: 'S.No',
          sortable: false
        },
        {
          key: 'user_Id',
          label : 'User Id',
          sortable: true
        },
        {
          key: 'first_name',
          sortable: true
        },
        {
          key: 'last_name',
          sortable: true
        },
        {
          key: 'nick_name',
          sortable: true
        },
        {
          key: 'email_id',
          sortable: true,
        },
        {
          key: 'apple_mail_id',
          sortable: true,
        },
        {
          key: 'apple_id',
          sortable: true,
        },
        {
          key: 'round_count',
          sortable: true,
        },
        {
          key: 'Verified',
          sortable: true
        },
        {
          key: 'HCP',
          sortable: true,
        },
        {
          key: 'created_date',
          sortable: true,
        },
        {
          key: 'actions',
          sortable: false,
        },
        {
          key: 'disable_popup',
          sortable: true,
        }
      ],

    }
  },
  methods: {
    userSort(data){
      // this.syncEnable = true
      // console.log('data------->>>>>',data);
      this.$emit('usersortingdata',data);
      // this.syncEnable = false
    },
    disablepopup(data){
      // this.syncEnable = false
      // console.log('data------->>>>>',data);
      this.$emit('disablepopupdata',data)
    },
    rowClicked(item,event) {
      var routename = this.rowClick.name
      var clickable = this.rowClick.item
      // console.log('item---',item)
      if (routename == 'users') {
        if (clickable == 'true') {
          // console.log('item---1111111',item)
          this.selectedRow = item
          // console.log('selectedRow---',this.selectedRow)
          var id = item.user_Id
          // console.log('id---',this.selectedRow)
          this.$router.push({ name: 'UsersDetails', query: { id } })
        }
      }
    },
    rowSelected(e){
    },
    toggleModal(index, operation) {
      this.$emit('handleCellClick', { index, operation })
    }

  },
  computed: {
    // checkedField() {
    //   if(this.$route.name == "Users"){
    //     let header = ['S.No','user_id','first_name', 'last_name', 'nick_name', 'email_id', 'Verified' , 'HCP', 'created_date', 'actions']
    //     return header
    //   }
    //   else{
    //   }
    // }, 
  }
}
