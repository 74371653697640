<template>
  <Modal :id="id" :title="title" @resetState="resetState">
    <template #modal-card-body>
      <form @submit.prevent="onSubmit" class="p-3">
        <b-form class="custom-form">
          <b-form-group :class="{ error: validation.hasError('firstName') }">
            <b-form-input
              type="text"
              v-model="firstName"
              :onkeypress="'return /[a-z]/i.test(event.key)'"
              required
              placeholder="Name"
            ></b-form-input>
            <span class="text-danger small">{{
              validation.firstError("firstName")
            }}</span>
          </b-form-group>

          <b-form-group :class="{ error: validation.hasError('lastName') }">
            <b-form-input
              type="text"
              v-model="lastName"
              onkeypress="return /[a-z]/i.test(event.key)"
              required
              placeholder="Last Name"
            ></b-form-input>
            <span class="text-danger small">{{
              validation.firstError("lastName")
            }}</span>
          </b-form-group>

          <b-form-group :class="{ error: validation.hasError('nickName') }">
            <b-form-input
              type="text"
              v-model="nickName"
              onkeypress="return /[a-z]/i.test(event.key)"
              maxlength="3"
              required
              placeholder="Nick Name"
            ></b-form-input>
            <!-- <span class="text-danger small">{{ validation.firstError('nickName') }}</span> -->
            <span class="text-danger small">{{
              validation.firstError("nickName")
            }}</span>
          </b-form-group>

          <b-form-group
            :class="{ error: validation.hasError('handicapIndex') }"
          >
            <b-form-input
              type="text"
              v-model="handicapIndex"
              onkeypress="return /[0-9 , .+]/i.test(event.key)"
              maxlength="2"
              equired
              placeholder="Handicap Index"
            ></b-form-input>
            <span class="text-danger small">{{
              validation.firstError("handicapIndex")
            }}</span>
          </b-form-group>

          <b-form-group :class="{ error: validation.hasError('email') }">
            <b-form-input
              type="email"
              v-model="email"
              required
              placeholder="Email"
              id="fieldDisabled"
            ></b-form-input>
            <span class="text-danger small">{{
              validation.firstError("email")
            }}</span>
          </b-form-group>

          <!-- <b-form-group v-if="!selectedRow" :class="{error: validation.hasError('password')}">
            <b-form-input type="password" v-model="password" required placeholder="Password"></b-form-input>
            <span class="text-danger small">{{ validation.firstError('password') }}</span>
          </b-form-group> -->
        </b-form>
      </form>
    </template>

    <template #modal-card-footer>
      <div class="text-center w-100">
        <b-button
          size="sm"
          pill
          v-if="isVerified === 0"
          @click="VerifyOnSubmit(userid)"
          variant="outline-success"
          class="px-4 py-2 mx-2"
          >Verify Email
        </b-button>
        <b-button
          size="sm"
          @click="onSubmit"
          pill
          variant="outline-success"
          class="px-4 py-2"
          >Done
        </b-button>
      </div>
    </template>
  </Modal>
</template>
<script>
import Modal from "../../../components/Modal";
import Vue from "vue";
import SimpleVueValidation from "simple-vue-validator";
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/services/eventBus.js"

const Validator = SimpleVueValidation.Validator.create({
  templates: {
    Empty: "This Field is required."
  }
});

function initialState() {
  return {
    firstName: "",
    lastName: "",
    nickName: "",
    handicapIndex: "",
    email: "",
    // password: "",
    title: "Add User",
    allowReset: false,
    isVerified : '',
    userid : '',
  };
}

export default {
  name: "AddAndEditModal",
  props: {
    id: {
      type: String
    },
    selectedRow: {},
    addModalCheck: {
      type: String
    }
  },
  components: {
    Modal
  },
  data() {
    return initialState();
  },
  validators: {
    firstName: function(value) {
      return Validator.value(value).required("This Field is mandatory");
    },
    lastName: function(value) {
      return Validator.value(value).required("This Field is mandatory");
    },
    nickName: function(value) {
        return Validator.value(value).required('This Field is mandatory').length(3);
    },
    // handicapIndex: function(value) {
    //   return Validator.value(value).required("This Field is mandatory");
    // },
    email: function(value) {
      return Validator.value(value)
        .required("This Field is mandatory")
        .email("Enter the Valid Email ID");
    }
    // password: function(value) {
    //   if(!this.selectedRow)
    //   return Validator.value(value).required("This Field is mandatory");
    // }
  },
  watch: {
    selectedRow: function(val) {
      if (val) {
        this.title = "Edit User";
        this.firstName = val.first_name;
        this.lastName = val.last_name;
        this.handicapIndex = val.handicap_index;
        this.nickName = val.nick_name;
        this.email = val.email;
        this.password = '';
        this.isVerified = val.is_verified
        this.userid = val.user_id

        // setTimeout(() => {
        //   document.getElementById('fieldDisabled').disabled = "disabled";
        // }, 700);  

      } else {
        this.title = "Add User";
      }
    },
    addModalCheck: function(val) {
      if (val == "addModal") {
        this.resetFromParent();
      }
    }
  },
  computed: {
    ...mapGetters("Users", ["getAddUserStatus"])
  },
  methods: {
    ...mapActions("Users", ["ADDNEWUSER", "EDITUSER","VERIFYEMAIL"]),

    // ADD NEW USER

    addNewUser(payload) {
      this.$root.$emit("bv::toggle::modal", "AddAndEditUserModal");
      this.ADDNEWUSER(payload).then(response => {
        if (response.status == 200) {
          EventBus.$emit('emittedEvent', response);                      
          this.$notify({
            type: "success",
            title: "Successfull",
            text: response.message
          });
                    
        } else {
          this.$notify({
            type: "warn",
            title: "Error",
            text: response.message
          });
        }
        this.resetFromParent();
      });
    },

    // EDIR USER DETAILS

    editUser(body) {
      this.$root.$emit("bv::toggle::modal", "AddAndEditUserModal");
      this.EDITUSER({ body }).then(response => {
        if (response.status == 200) {
      EventBus.$emit('emittedEvent', response);                
          this.$notify({
            type: "success",
            title: "Successfull",
            text: response.message
          });
          
        } else {
          this.$notify({
            type: "warn",
            title: "Error",
            text: response.message
          });
        }
      });
    },

  // Verify email

    isVerifyEmail(body) {
      // console.log('body------',body)
      this.$root.$emit("bv::toggle::modal", "AddAndEditUserModal");
      this.VERIFYEMAIL({ body, reloadUserList: false }).then((response) => {
        // console.log('response----datea------',response);
        if (response.status == 200) {
          EventBus.$emit('emittedEvent', response);                
          this.$notify({
            type: "success",
            title: "Successfull",
            text: response.message
          });
          this.getUserDetailsData();
        } else {
          this.$notify({
            type: "warn",
            title: "Error",
            text: response.message
          });
        }
      });
    },

    // ADD OR EDIT BUTTON SUBMIT 

    onSubmit() {
      this.$validate().then(success => {
        let {
          firstName,
          lastName,
          nickName,
          email,
          password,
          handicapIndex,
          selectedRow
        } = this;
        if (success) {
          let payload = {
            ...(selectedRow ? { user_id: selectedRow.user_id } : ""),
            first_name: firstName,
            last_name: lastName,
            nick_name: nickName,
            email: email,
            handicap_index: handicapIndex
            // password: password
          };
          if (!selectedRow) {
            this.addNewUser(payload);
          } else {
            this.editUser(payload);
          }
        }
      });
    },

    VerifyOnSubmit() {
      this.$validate().then(success => {
        let {
          firstName,
          lastName,
          nickName,
          email,
          password,
          handicapIndex,
          selectedRow
        } = this;
        if (success) {
          let payload = {
            user_id: this.userid,
            first_name: firstName,
            last_name: lastName,
            nick_name: nickName,
            email: email,
            handicap_index: handicapIndex
          };
          this.isVerifyEmail(payload);
        }
      });
    },

    // RESET THE MODAL STATE

    resetState(force = false) {
      if (this.allowReset || force) {
        this.validation.reset();
        Object.assign(this.$data, initialState());
      }
    },
    resetFromParent() {
      this.resetState(true);
    }
  }
};
</script>
