import Vue from "vue";
import VueResource from "vue-resource";

Vue.use(VueResource);
Vue.http.options.root = process.env.VUE_APP_API_HOST;
Vue.http.headers.common["Access-Control-Allow-Origin"] = "*";
Vue.http.headers.common["Content-Type"] = "application/json";
Vue.http.interceptors.push(function(request, next) { 
//   let token = JSON.parse(localStorage.getItem('ch_auth_token'))
//   if(token == null){
//     token = ''
//   } 
//   Vue.http.headers.common['Authorization'] = 'Bearer ' + token;
  next((response)=>{
    
    // if(response.status == 401){     
    //   router.push({ name: 'login' });
    // }
  });
});
