import RoundsTable from '@/components/Rounds/RoundsTable'
import { mapGetters, mapActions } from "vuex";
import { ModalMixins } from '@/mixins'
import SimpleVueValidation from 'simple-vue-validator';
import FilterModal from '@/components/FilterModal'
const Validator = SimpleVueValidation.Validator.create({
  templates: {
    Empty: 'This Field is required.'
  }
});;
export default {
  name: 'rounds',
  components: {
    RoundsTable,
    FilterModal
  },
  props: [],
  data() {
    return {
      show_internal_user: 1,
      __status:1,
      rows: null,
      perPage: null,
      currentPage: 1,
      activeTab: 'All',
      startDate: "",
      endDate: "",
      roundsSearch:'',
      FilterModalId: 'FilterModal',
      json_data: [],
      filename: 'Rounds-List.csv',
      sort_column_name :'',
      sort_column_type :'',
      fetchSelectedData: '',
      showRoundsTable : false,
      playerName: '',
      courseName: '',
      appleId: '',
      appleEmailId: '',
      gameName: ''
    }
  },
  validators: {
    startDate: function (value) {
      return Validator.value(value).required()
    },
    endDate: function (value) {
      return Validator.value(value).required()
    }
  },
  created() {
    this.FETCHGAMELIST();
  },
  computed: {
    ...mapGetters("Rounds", ["getRoundData","gameNames" ])

  },
  mounted() {
    this.getRoundData = []
    console.log('get----getRoundData---->>>',this.getRoundData)
    this.showRoundsTable = false
    // let params = {
    //   internal_user : this.show_internal_user
    // }
    // this.getRoundList(params)
  },
  watch:{
    // roundsSearch: function () {
    //   clearTimeout(this.timeVariable);
    //   let that = this;
    //   this.timeVariable = setTimeout(function () {
    //     that.searchData();
    //   }, 1000);
    // }
    playerName: function (){
      clearTimeout(this.timeVariable);
      let that = this;
      this.timeVariable = setTimeout(function (){
        that.searchPlayer();
        that.gameName = '';
      }, 1000);

    },
    courseName: function (){
      clearTimeout(this.timeVariable);
      let that = this;
      this.timeVariable = setTimeout(function (){
        that.searchCourse();
        that.gameName = '';
      }, 1000);

    },
    appleId: function (){
      clearTimeout(this.timeVariable);
      let that = this;
      this.timeVariable = setTimeout(function (){
        that.searchAppleId();
        that.gameName = '';
      }, 1000);

    },
    appleEmailId: function (){
      clearTimeout(this.timeVariable);
      let that = this;
      this.timeVariable = setTimeout(function (){
        that.searchAppleEmailId();
        that.gameName = '';
      }, 1000);

    },
    gameName: function () {
      let params = {
        internal_user : this.show_internal_user,
        round_filter : this.activeTab,
        game_name: this.gameName,
        search: '1'
      }
      this.getRoundList(params);
    },
  },
  methods: {
    ...mapActions("Rounds", ["GETROUNDS","DOWNLOADROUNDSLIST","FETCHGAMELIST"]),

  searchPlayer() {
    let params = {
      internal_user : this.show_internal_user,
      round_filter : this.activeTab,
      players_name: this.playerName,
      search: '1'
    }
    this.getRoundList(params);
  },

  searchCourse() {
    let params = {
      internal_user : this.show_internal_user,
      round_filter : this.activeTab,
      course_name: this.courseName,
      search: '1'
    }
    this.getRoundList(params);
  },

  searchAppleId(){
    let params = {
      internal_user : this.show_internal_user,
      round_filter : this.activeTab,
      apple_id: this.appleId,
      search: '1'
    }
    this.getRoundList(params);
  },

  searchAppleEmailId() {
    let params = {
      internal_user : this.show_internal_user,
      round_filter : this.activeTab,
      apple_email_id: this.appleEmailId,
      search: '1'
    }
    this.getRoundList(params);
  },

    //GET ROUNDS LIST

    fetchData(value){
      // console.log('value---->>>>>DDDd',value)
      this.fetchSelectedData = value
      let params = {
        filter : this.activeTab,
        search: this.roundsSearch,
        internal_user : this.show_internal_user,
        round_filter : this.fetchSelectedData
      }
      this.getRoundList(params);
    },

    showInternalUser(data){
      // console.log('data----->>>>>>>',data.value);
      if(data.value == true){
        this.show_internal_user = 0;
        let params = {
          filter : this.activeTab,
          search: this.roundsSearch,
          internal_user : this.show_internal_user,
          round_filter : this.fetchSelectedData
        }
        this.getRoundList(params);
      }else{
        this.show_internal_user = 1;
        let params = {
          filter : this.activeTab,
          search: this.roundsSearch,
          internal_user : this.show_internal_user,
          round_filter : this.fetchSelectedData
        }
        this.getRoundList(params);
      }
    },

    getRoundList(params) {
      this.GETROUNDS(params).then((response) => {
          this.showRoundsTable = true
          let responseData = response.data.meta.pagination;
          this.rows = responseData.total
          this.perPage = responseData.per_page
          this.currentPage = responseData.current_page
      })
    },



    //SEARCH

    searchData(){
      var params = {
        internal_user : this.show_internal_user,
        filter:this.activeTab,
        search: this.roundsSearch,
        round_filter : this.fetchSelectedData,
        page: 1,
        ...(
          this.activeTab
            ? { filter : this.activeTab }
            : {
              start_date: this.startDate,
              end_date: this.endDate
            })
      }
      this.GETROUNDS(params).then((response) => {
        let responseData = response.data.meta.pagination;
        this.rows = responseData.total
        this.perPage = responseData.per_page
        this.currentPage = responseData.current_page
      })
    },

    //SORTING

    roundSorting(data){
      // console.log('data0-----',data);
      this.sort_column_type = data.sortDesc == false ? 'asc' : 'desc'
      if(data.sortBy == "date") {
        this.sort_column_name = 'round_current_date'
      }
      else if(data.sortBy == "Total Bets") {
        this.sort_column_name = 'bet_amount'
      }
      else if(data.sortBy == "round_duration") {
        this.sort_column_name = 'round_total_time'
      }
      else if(data.sortBy == "course_name") {
        this.sort_column_name = 'place_name'
      }
      else if(data.sortBy == "status") {
        this.sort_column_name = 'is_end_round'
      }
      else if(data.sortBy == "country") {
        this.sort_column_name = 'country'
      }
      else if(data.sortBy == "Holes") {
        this.sort_column_name = 'hole'
      }
      else if(data.sortBy == "number_of_players") {
        this.sort_column_name = 'player_count'
      }
      else if(data.sortBy == "number_of_groups") {
        this.sort_column_name = 'device_count'
      }
      else{
        this.sort_column_name = data.sortBy
      }
      // console.log(this.sort_column_name,'Column name')
      // console.log(this.sort_column_type,'Column sort')
      let params = {
        internal_user : this.show_internal_user,
        filter : this.activeTab,
        column_name : this.sort_column_name,
        order_by : this.sort_column_type,
        round_filter : this.fetchSelectedData,
        search: this.roundsSearch,
      }
      this.getRoundList(params)
    },

    //DOWNLOAD ROUNDS LIST

    downloadRoundsList(){
      let params ={
        internal_user : this.show_internal_user,
      }
      this.DOWNLOADROUNDSLIST(params).then((response) => {
      // console.log('response------11111111111111111',response)
        if (response.status === 200) {
          // window.open(
          //   response.url,
          //   "_blank"
          // );
          this.json_data = response.data.data
          console.log('response.data-------',this.json_data)
          setTimeout(() => {
            this.$refs.fileInput.$el.click();
          }, 800);
        } else {
        }
      })
    },

    //HANDLE PAGINATION

    handlePagination(val) {
      let params = {
        internal_user : this.show_internal_user,
        search : this.roundsSearch,
        page: val,
        round_filter : this.fetchSelectedData,
        ...(
          this.activeTab
            ? { filter : this.activeTab }
            : {
              start_date: this.startDate,
              end_date: this.endDate
            }),
        column_name : this.sort_column_name,
        order_by : this.sort_column_type
      }
      window.scrollTo({ top: 0, behavior: 'smooth' })
      this.getRoundList(params)
    },

    //HANDLE FILTER TABS

    handleFilter(value = 'all') {
      this.activeTab = value
      this.startDate = null
      this.endDate = null
      this.currentPage = 1;
      this.$validate()
      let params = {
        internal_user : this.show_internal_user,
        search : this.roundsSearch,
        page: this.currentPage,
        filter: value,
        round_filter : this.fetchSelectedData
      }
      this.getRoundList(params)
    },

    //FILTER BY DATE RANGE

    filterByDateRange(data) {

      this.startDate = data.startDate
      this.endDate = data.endDate

      let params = {
        internal_user : this.show_internal_user,
        // page: this.currentPage,
        start_date: this.startDate,
        end_date: this.endDate,
        round_filter : this.fetchSelectedData
      }

      this.getRoundList(params);
      this.activeTab = ''
    },


  }
}
