export const getUsersData = (state) => {
    return state.list
}

export const getFullUserData = (state) => {
    return state.fullList
}

export const getUserDetails = state => {
    return state.getUserDetails;
};
export const getUserRounds = state => {
    return state.userRoundsDetails;
};

export const getUserTransactions = state => {
    return state.userTransactionDetails;
};

export const getUserBalance = state => {
    return state.userBalanceDetails;
};

export const getUserGames = (state) => {
    return state.userGameDetails;
};

export const getRoundsPaginationDetails = (state) => {
    return state.roundsPaginationDetails;
};

export const getTransactionsPaginationDetails = (state) => {
    return state.transactionPaginationDetails;
};